import React from "react";
import { Typography, withStyles, Toolbar, ListItem, List, WithStyles, CircularProgress } from "@material-ui/core";
import { styles } from "./air-quality-slider.styles";
import strings from "../../localization/strings";
import { RouteTotalAirQuality } from "../../types";
import PollutantSlider from "./pollutant-slider";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  routeTotalExposures: RouteTotalAirQuality[];
  displayRouteAirQuality: boolean;
  loadingRouteAirQuality: boolean;
}

/**
 * Interface describing component state
 */
interface State {}

/**
 * Component which displays air quality per pollutant in sliders list
 */
class AirQualitySlider extends React.Component<Props, State> {

  /**
   * Component constructor
   *
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  
  /**
   * Render method to display air quality for route sliders
   */
  public render = () => {
    const { classes, routeTotalExposures, loadingRouteAirQuality, displayRouteAirQuality } = this.props;

    if (!displayRouteAirQuality) {
      return null;
    }
    
    return (
      <>
        <Toolbar>
          <Typography variant="h2">{ strings.airQuality }</Typography>
        </Toolbar>
        { loadingRouteAirQuality ?
          <CircularProgress size={ 40 } color="inherit" className={ classes.airQualityDataLoader }/>
          :
          <List>
            { AirQualitySlider.getRenderedSlidersList(routeTotalExposures) }
          </List>
        }
      </>
    );
  };

  /**
   * Builds list of ListItems for pollutants exposure data
   * 
   * @param routeTotalExposures air quality total data for different pollutants for the route
   */
  static getRenderedSlidersList(routeTotalExposures: RouteTotalAirQuality[]) {
    return routeTotalExposures
      .filter(pollutantData => pollutantData.pollutant.limit)
      .map(pollutantData => (
        <ListItem>
          <PollutantSlider
            pollutant={ pollutantData.pollutant }
            pollutionValue={ pollutantData.pollutionValue }
          />
        </ListItem>
      ));
  }

}

export default withStyles(styles)(AirQualitySlider);