import { createStyles } from "@material-ui/core";
import { globalStyles } from "../../../styles/globalStyles";
import theme from "../../../theme/theme";

export const styles = createStyles({
  ...globalStyles,

  loadingContainer: {
    paddingTop: theme.spacing(2),
    height: "100%",
    display: "flex",
    justifyContent: "center"
  },

  scrollablePollutantsContainer: {
    flex: 1,
    overflow: "auto",
    overflowX: "hidden",
    scrollbarColor: "rgba(0,0,0,0.3) transparent",
    scrollbarWidth: "auto",
    paddingBottom: theme.spacing(12),
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,0.5)",
      border: "none"
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
      color: "transparent"
    },
    [theme.breakpoints.down("lg")]: {
      width: `calc(720px + ${theme.spacing(4)}px)`
    },
    [theme.breakpoints.down("md")]: {
      width: `calc(500px + ${theme.spacing(4)}px)`
    }
  },

  pollutantCard: {
    margin: `${theme.spacing(2)}px 0px`,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    width: 880,
    [theme.breakpoints.down("lg")]: {
      width: 720
    },
    [theme.breakpoints.down("md")]: {
      width: 500
    }
  },

  pollutantCardTitle: {
    padding: `${theme.spacing(1)}px 0px`,
    display: "flex",
    alignItems: "end"
  },

  pollutantCardBody: {
    padding: `${theme.spacing(2)}px 0px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },

  saveButton: {
    ...globalStyles.primaryButton,
    maxWidth: 120,
    maxHeight: 36,
    width: "100%",
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down("lg")]: {
      maxWidth: 100
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: 80
    }
  },

  pollutantInput: {
    height: 48,
    color: theme.palette.primary.main,
    width: 210,
    "& .MuiInput-underline:before": {
      borderBottom: "2px solid rgba(0, 0, 0, 0.54)"
    },
    "& .MuiInput-underline:after": {
      borderBottom: `3px solid ${theme.palette.primary.main}`
    },
    [theme.breakpoints.down("lg")]: {
      width: 160
    },
    [theme.breakpoints.down("md")]: {
      width: 100
    }
  }

});