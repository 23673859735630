/* tslint:disable */
/* eslint-disable */
/**
 * Megasense data portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Penalty for the maximum allowed pollution values of the pollutant
 * @export
 * @interface PollutantPenalty
 */
export interface PollutantPenalty {
    /**
     * 
     * @type {string}
     * @memberof PollutantPenalty
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PollutantPenalty
     */
    pollutantId?: string;
    /**
     * 
     * @type {number}
     * @memberof PollutantPenalty
     */
    penalty: number;
    /**
     * 
     * @type {number}
     * @memberof PollutantPenalty
     */
    threshold: number;
}

export function PollutantPenaltyFromJSON(json: any): PollutantPenalty {
    return PollutantPenaltyFromJSONTyped(json, false);
}

export function PollutantPenaltyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PollutantPenalty {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'pollutantId': !exists(json, 'pollutantId') ? undefined : json['pollutantId'],
        'penalty': json['penalty'],
        'threshold': json['threshold'],
    };
}

export function PollutantPenaltyToJSON(value?: PollutantPenalty | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'pollutantId': value.pollutantId,
        'penalty': value.penalty,
        'threshold': value.threshold,
    };
}


