/* tslint:disable */
/* eslint-disable */
/**
 * Megasense data portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Icon type of a routing mode
 * @export
 * @enum {string}
 */
export enum RoutingModeIcon {
    Heart = 'heart',
    ThumbUp = 'thumbUp',
    Insight = 'insight',
    Timer = 'timer',
    TimeLine = 'timeLine',
    Eco = 'eco'
}

export function RoutingModeIconFromJSON(json: any): RoutingModeIcon {
    return RoutingModeIconFromJSONTyped(json, false);
}

export function RoutingModeIconFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoutingModeIcon {
    return json as RoutingModeIcon;
}

export function RoutingModeIconToJSON(value?: RoutingModeIcon | null): any {
    return value as any;
}

