import { ArrowBackIos, ArrowForwardIos, VisibilityOff, RemoveRedEye } from "@material-ui/icons";
import { Box, Paper, Typography, Toolbar, TextField, MenuItem, IconButton, CircularProgress, Grid } from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { History } from "history";
import React from "react";
import { connect } from "react-redux";
import ExposureBarChart from "../../charts/exposure-bar-chart";
import { Dispatch } from "redux";
import Api from "../../../api";
import { Pollutant } from "../../../generated/client";
import strings from "../../../localization/strings";
import { ReduxActions, ReduxState } from "../../../store";
import { NullableToken, HeaderNavigationLinks, StatisticScope, ExposurePollutantData } from "../../../types";
import AppLayout from "../../layouts/app-layout/app-layout";
import { styles } from "./statistics-screen.styles";
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import StatisticsUtils from "../../../utils/statistics-utils";
import moment, { Moment } from "moment";
import { ToggleButton } from "@material-ui/lab";
import theme from "../../../theme/theme";
import PollutantSlider from "../../pollutant-exposures/pollutant-slider";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  accessToken?: NullableToken;
  keycloak?: Keycloak.KeycloakInstance;
  history: History<History.LocationState>;
}

/**
 * Interface describing component state
 */
interface State {
  loading: boolean;
  selectedDate: Moment;
  exposureStatisticsDataArray?: ExposurePollutantData[];
  pollutants?: Pollutant[];
  statisticScope: StatisticScope;
  displayedPollutant?: Pollutant;
  error?: any;
}

/**
 * Component for statistics screen
 */
class StatisticsScreen extends React.Component<Props, State> {

  /**
   * Component constructor
   * 
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      selectedDate: moment(),
      statisticScope: StatisticScope.DAY
    };
  }
  
  /**
   * Component life cycle method
   */
  public componentDidMount = async () => {
    const { accessToken, history } = this.props;
    const { selectedDate, statisticScope } = this.state;

    if (!accessToken) {
      history.push("/");
    }

    this.setState({
      loading: true
    });

    const exposureInstances = await this.fetchExposureData();
    const pollutants = await this.fetchPollutantData();

    if (!pollutants || !exposureInstances) {
      return;
    }

    // TODO fix time with no info
    const preProcessedDate = StatisticsUtils.exposureStatisticsPreprocess(exposureInstances, statisticScope, selectedDate, pollutants);

    this.setState({
      pollutants: pollutants,
      exposureStatisticsDataArray: preProcessedDate,
      loading: false
    });
  };

  /**
   * Component life cycle method
   */
  public componentDidUpdate = async (prevProps: Props, prevState: State) => {
    const { selectedDate, statisticScope, pollutants } = this.state;

    if (prevState.statisticScope !== statisticScope || prevState.selectedDate !== selectedDate) {
      const exposureInstances = await this.fetchExposureData();
  
      if (!pollutants || !exposureInstances) {
        return;
      }
  
      const preProcessedDate = StatisticsUtils.exposureStatisticsPreprocess(exposureInstances, statisticScope, selectedDate, pollutants);
  
      this.setState({
        exposureStatisticsDataArray: preProcessedDate
      });
    }
  };

  /**
   * Component render
   */
  public render = () => {
    const { accessToken, keycloak } = this.props;
    const { error } = this.state;

    return (
      <AppLayout
        accessToken={ accessToken }
        keycloak={ keycloak }
        showDrawer={ true }
        error={ error }
        clearError={ this.clearError }
        currentHeaderNavigationLink={ HeaderNavigationLinks.statistics }
        drawerContent={
          this.renderStatisticsDrawer()
        }
      >
        <Box p={ 4 }>
          <Paper>
            { this.renderChartHeader() }
            { this.renderChartBody() }
          </Paper>
          <Paper style={{ marginTop: theme.spacing(4) }}>
            { this.renderPollutantDetailHeader() }
            { this.renderPollutantDetailBody() }
          </Paper>
        </Box>
      </AppLayout>
    );
  };

  /**
   * Renders statistic bar chart
   */
  private statisticsBarChart = () => {
    const { classes } = this.props;
    const {
      statisticScope,
      exposureStatisticsDataArray,
      displayedPollutant
    } = this.state;

    if (!displayedPollutant?.id) {
      return null;
    }

    const displayedPollutantExposure = exposureStatisticsDataArray?.find(exposureStatisticsData => exposureStatisticsData.pollutant.id === displayedPollutant.id || "");

    if (!displayedPollutantExposure) {
      return null;
    }

    // TODO duration of the exposure when navigate button click

    return (
      <Box className={ classes.chartContainerStyling }>
        <ExposureBarChart
          pollutant={ displayedPollutant }
          displayedExposureInstances={ displayedPollutantExposure.exposureData }
          statisticScope={ statisticScope }
        />
      </Box>
    );
  };

  /**
   * Renders pollutant detail header
   */
  private renderPollutantDetailHeader = () => {
    const { classes } = this.props;
    const { statisticScope } = this.state;

    return (
      <Box className={ classes.pollutantDetailHeader }>
        <Typography className={ classes.statisticsHeader }>
          { strings.statistics.exposure[statisticScope] }
        </Typography>
      </Box>
    );
  };

  /**
   * Renders pollutant detail body
   */
  private renderPollutantDetailBody = () => {
    const { classes } = this.props;
    const { loading, exposureStatisticsDataArray } = this.state;

    if (!exposureStatisticsDataArray) {
      return null;
    }

    if (loading) {
      return (
        <Box className={ classes.pollutantDetailBodyLoading }>
          <CircularProgress size={ 40 }/>
        </Box>
      );
    }

    return (
      <Box className={ classes.pollutantDetailBody }>
        <Grid container spacing={ 3 }>
          { exposureStatisticsDataArray.map(exposureStatisticsData =>
            <Grid item>
              <PollutantSlider
                pollutant={ exposureStatisticsData.pollutant }
                pollutionValue={ StatisticsUtils.exposureStatisticsDataReduce(exposureStatisticsData.exposureData) }
              />
            </Grid>)}
        </Grid>
      </Box>
    );
  };

  /**
   * Renders chart header
   */
  private renderChartHeader = () => {
    const { classes } = this.props;

    return (
      <Box className={ classes.chartHeader }>
        { this.renderDateString() }
        { this.renderTimeSelector() }
      </Box>
    );
  };

  /**
   * Renders chart header
   */
  private renderChartBody = () => {
    const { classes } = this.props;
    const { loading } = this.state;

    if (loading) {
      return (
        <Box className={ classes.chartBodyLoading }>
          <CircularProgress size={ 40 }/>
        </Box>
      );
    }

    return (
      <Box className={ classes.chartBody }>
        { this.renderStatisticsPollutantToolbar() }
        { this.statisticsBarChart() }
      </Box>
    );
  };

  /**
   * Renders date string
   */
  private renderDateString = () => {
    const { classes } = this.props;
    const { selectedDate, statisticScope } = this.state;

    return (
      <Typography className={ classes.statisticsHeader }>
        { StatisticsUtils.formatDateString(statisticScope, selectedDate) }
      </Typography>
    );
  };

  /**
   * Renders time selector
   */
  private renderTimeSelector = () => (
    <Box display="flex" alignItems="center">
      { this.renderTimeScopeSelector() }
      { this.renderArrowButtons() }
    </Box>
  );

  /**
   * Renders time scope selector
   */
  private renderTimeScopeSelector = () => {
    const { classes } = this.props;
    const { statisticScope } = this.state;

    return (
      <TextField
        select
        color="primary"
        variant="outlined"
        className={ classes.scopeSelector }
        value={ statisticScope }
        onChange={ this.onSelectedScopeChange }
        InputProps={{
          classes: {
            notchedOutline: classes.notchedOutline
          }
        }}
      >
        { Object.values(StatisticScope).map(scopeOption => (
          <MenuItem
            key={ scopeOption }
            value={ scopeOption }
          >
            { strings.statistics.scope[scopeOption as keyof object] }
          </MenuItem>
        ))
        }
      </TextField>
    );
  };

  /**
   * Renders arrow buttons
   */
  private renderArrowButtons = () => {
    const { classes } = this.props;

    return (
      <Box display="flex">
        <IconButton
          className={ classes.iconButton }
          onClick={ this.onSelectedTimeBack }
        >
          <ArrowBackIos htmlColor={ theme.palette.primary.main }/>
        </IconButton>
        <IconButton
          className={ classes.iconButton }
          onClick={ this.onSelectedTimeForward }
        >
          <ArrowForwardIos htmlColor={ theme.palette.primary.main }/>
        </IconButton>
      </Box>
    );
  };

  /**
   * Renders pollutant toggle buttons
   */
  private renderStatisticsPollutantToolbar = () => {
    const { pollutants } = this.state;
    if (!pollutants) {
      return null;
    }

    return (
      <Toolbar>
        <Grid container spacing={ 2 }>
          { pollutants.map(pollutant => (
            <Grid item>
              { this.renderPollutantToolbarButton(pollutant) }
            </Grid>
          ))
          }
        </Grid>
      </Toolbar>
    );
  };

  /**
   * Render pollutant toolbar button
   *
   * @param pollutant pollutant data
   */
  private renderPollutantToolbarButton = (pollutant: Pollutant) => {
    const { classes } = this.props;
    const { displayedPollutant } = this.state;

    return (
      <ToggleButton
        className={ classes.pollutantToggleButton }
        color="primary"
        selected={ pollutant.id === displayedPollutant?.id }
        onClick={ this.onPollutantToggleButtonClick(pollutant) }
      >
        { pollutant.id === displayedPollutant?.id ? <RemoveRedEye/> : <VisibilityOff/> }
        <Typography style={{ paddingLeft: theme.spacing(1) }}>
          { pollutant.displayName }
        </Typography>
      </ToggleButton>
    );
  };

  /**
   * Fetches exposure data
   */
  private fetchExposureData = async () => {
    const { accessToken } = this.props;
    const { statisticScope, selectedDate } = this.state;

    if (!accessToken) {
      return;
    }

    const [ startDate, endDate ] = StatisticsUtils.getTimeRange(statisticScope, selectedDate);
    try {
      return await Api.getExposureInstancesApi(accessToken).listRoutePollutionExposureRecords({
        createdAfter: startDate.toDate(),
        createdBefore: endDate.toDate()
      });
    } catch (error) {
      this.setState({
        error: error
      });
    }
  };

  /**
   * Fetches pollutant data
   */
  private fetchPollutantData = async () => {
    try {
      const fetchedPollutants = await Api.getPollutantsApi().getPollutants({});

      this.setState({
        displayedPollutant: fetchedPollutants[0]
      });

      return fetchedPollutants;
    } catch (error) {
      this.setState({
        error: error
      });
    }
  };

  /**
   * Pollutant toggle button click handler
   */
  private onPollutantToggleButtonClick = (pollutant: Pollutant) => () => {
    this.setState({
      displayedPollutant: pollutant
    });
  };

  /**
   * Clears the error
   */
  private clearError = () => {
    this.setState({
      error: undefined
    });
  };

  /**
   * Renders statistics drawer
   */
  private renderStatisticsDrawer = () => {
    const { classes } = this.props;

    return (
      <Box className={ classes.drawerContainer }>
        { this.showCalendar() }
      </Box>
    );
  };

  /**
   * Method for rendering calendar
   */
  private showCalendar = () => {
    const { selectedDate } = this.state;
    return (
      <MuiPickersUtilsProvider utils={ MomentUtils }>
        <DatePicker
          value={ selectedDate }
          onChange={ action => this.onDateChange(action) }
          variant="static"
          disableToolbar={ true }
        />
      </MuiPickersUtilsProvider>
    );
  };

  /**
   * Changes selected scope
   * @param event event
   */
  private onSelectedScopeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { selectedDate } = this.state;
    const selectedScope = event.target.value as StatisticScope;
    const startOfScope = StatisticsUtils.startOfScope(selectedScope, selectedDate);
    
    this.setState({
      selectedDate: startOfScope,
      statisticScope: selectedScope
    });
  };

  /**
   * Decrement selected time by scope
   */
  private onSelectedTimeBack = () => {
    const { statisticScope, selectedDate } = this.state;

    const newDate = StatisticsUtils.dateDecrement(statisticScope, selectedDate);

    this.setState({
      selectedDate: newDate
    });
  };

  /**
   * Increment selected time by scope
   */
  private onSelectedTimeForward = () => {
    const { statisticScope, selectedDate } = this.state;

    const newDate = StatisticsUtils.dateIncrement(statisticScope, selectedDate);

    this.setState({
      selectedDate: newDate
    });
  };

  /**
   * method for changing calendar date
   * @param action material-UI date picker
   */
  private onDateChange = (action: MaterialUiPickersDate) => {
    if (!action) {
      return;
    }

    const selectedDate = moment(action.toDate());
    this.setState({
      statisticScope: StatisticScope.DAY,
      selectedDate: selectedDate
    });
  };

}

/**
 * Redux mapper for mapping store state to component props
 * @param state store state
 */
export function mapStateToProps(state: ReduxState) {
  return {
    accessToken: state.auth.accessToken,
    keycloak: state.auth.keycloak
  };
}

/**
 * Redux mapper for mapping component dispatches 
 * 
 * @param dispatch dispatch method
 */
export function mapDispatchToProps(dispatch: Dispatch<ReduxActions>) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StatisticsScreen));