import Geocode from "react-geocode";
import { Address } from "../generated/client";

/**
 * Utils methods for map
 */
export default class MapUtils {

  /**
   * Translates address into lat and lon coordinates
   * @param address address
   * @returns lat and lon coordinates
   */
  public static getLocation = async (address: Address): Promise<[number, number] | null> => {
    const { streetAddress, postalCode, city, country } = address;
    const addressString = `${streetAddress}, ${city}, ${country}, ${postalCode}`;
    const geocodingResponse = await Geocode.fromAddress(addressString, process.env.REACT_APP_GOOGLE_API_KEY);
    if (geocodingResponse.results.length >= 0) {
      const { lat, lng } = geocodingResponse.results[0].geometry.location;
      return [ Number.parseFloat(lat), Number.parseFloat(lng) ] as [number, number];
    }

    return null;
  };

}