/* tslint:disable */
/* eslint-disable */
/**
 * Megasense data portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Location,
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
    PollutionEntry,
    PollutionEntryFromJSON,
    PollutionEntryFromJSONTyped,
    PollutionEntryToJSON,
} from './';

/**
 * air quality data for the given location
 * @export
 * @interface AirQuality
 */
export interface AirQuality {
    /**
     * 
     * @type {Location}
     * @memberof AirQuality
     */
    location: Location;
    /**
     * 
     * @type {Array<PollutionEntry>}
     * @memberof AirQuality
     */
    pollutionValues: Array<PollutionEntry>;
}

export function AirQualityFromJSON(json: any): AirQuality {
    return AirQualityFromJSONTyped(json, false);
}

export function AirQualityFromJSONTyped(json: any, ignoreDiscriminator: boolean): AirQuality {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'location': LocationFromJSON(json['location']),
        'pollutionValues': ((json['pollutionValues'] as Array<any>).map(PollutionEntryFromJSON)),
    };
}

export function AirQualityToJSON(value?: AirQuality | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'location': LocationToJSON(value.location),
        'pollutionValues': ((value.pollutionValues as Array<any>).map(PollutionEntryToJSON)),
    };
}


