import { WithStyles, withStyles } from "@material-ui/core";
import React from "react";
import { styles } from "./colored-route.styles";
import { LatLng } from "leaflet";
import { Polyline } from "react-leaflet";
import { AirQuality, Pollutant, PollutionEntry } from "../../../generated/client";
import RoutingUtils from "../../../utils/routing-utils";
import { HotLine } from "./hot-line";

const BASE_COLOR = "#277B90";
const LOW_DENSITY_COLOR = "#82bac7";
const HIGH_DENSITY_COLOR = "#ff3964";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  coordinates: LatLng[];
  airQualityEntries?: AirQuality[];
  baseColor?: string;
  coloredPollutant: Pollutant;
  selected?: boolean;
  onClick?: () => void;
}

/**
 * Interface describing component state
 */
interface State {
}

/**
 * Component for air quality colored route
 */
class ColoredRoute extends React.Component<Props, State> {

  /**
   * Component constructor
   * 
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
    };
  }

  /**
   * Renders hotline
   */
  private renderHotLine = () => {
    const {
      airQualityEntries,
      coloredPollutant,
      selected,
      baseColor
    } = this.props;
    const { recommendation, limit } = coloredPollutant;
    const gradientRange = (limit || 0) - (recommendation || 0);

    if (!airQualityEntries) {
      return null;
    }

    const hotlineData = airQualityEntries.map(airQualityEntry => ([
      airQualityEntry.location.latitude,
      airQualityEntry.location.longitude,
      (this.findPollutionValue(airQualityEntry.pollutionValues) - (recommendation || 0)) / gradientRange
    ]));

    const hotlinePalette: { [z: number]: string } = {};

    // Construct the palette array for hotline
    Array.from(Array(10).keys()).forEach(value => {
      const fraction = (value + 1) / 10;
      hotlinePalette[fraction] = RoutingUtils.getPollutantColorFaction(HIGH_DENSITY_COLOR, LOW_DENSITY_COLOR, baseColor || BASE_COLOR, fraction);
    });

    return (
      <HotLine data={ hotlineData } palette={ hotlinePalette } weight={ selected ? 6 : 4 }/>
    );
  };

  /**
   * Find the pollutant value in a pollution entry array
   * 
   * @param pollutionValues pollution value array
   */
  private findPollutionValue = (pollutionValues: PollutionEntry[]) => {
    const { coloredPollutant } = this.props;

    return pollutionValues.find(pollutionValue => pollutionValue.pollutantId === coloredPollutant.id)?.value || 0;
  };

  /**
   * Component render 
   */
  public render = () => {
    const {
      airQualityEntries,
      coordinates,
      selected,
      baseColor,
      onClick
    } = this.props;

    // Show the base polyline when the airQuality is not ready 
    return (
      <>
        { this.renderHotLine() }
        <Polyline
          positions={ coordinates }
          color={ airQualityEntries ? "transparent" : baseColor || BASE_COLOR }
          lineCap="round"
          lineJoin="bevel"
          weight={ selected ? 6 : 4 }
          onclick={ onClick }
        />
      </>
    );
  };

}

export default withStyles(styles)(ColoredRoute);