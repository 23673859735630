/* tslint:disable */
/* eslint-disable */
/**
 * Megasense data portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AirQuality,
    AirQualityFromJSON,
    AirQualityFromJSONTyped,
    AirQualityToJSON,
    PollutionEntry,
    PollutionEntryFromJSON,
    PollutionEntryFromJSONTyped,
    PollutionEntryToJSON,
} from './';

/**
 * Read-only air quality data for the array of coordinates of the route and corresponding exposure values
 * @export
 * @interface RoutePointsAirQuality
 */
export interface RoutePointsAirQuality {
    /**
     * List of air qualities for the coordinates passed in original request
     * @type {Array<AirQuality>}
     * @memberof RoutePointsAirQuality
     */
    readonly airQualityEntries: Array<AirQuality>;
    /**
     * List of exposure values that the user would consume during the trip along the coordinates route for the given time
     * @type {Array<PollutionEntry>}
     * @memberof RoutePointsAirQuality
     */
    readonly pollutionDataTotals: Array<PollutionEntry>;
}

export function RoutePointsAirQualityFromJSON(json: any): RoutePointsAirQuality {
    return RoutePointsAirQualityFromJSONTyped(json, false);
}

export function RoutePointsAirQualityFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoutePointsAirQuality {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'airQualityEntries': ((json['airQualityEntries'] as Array<any>).map(AirQualityFromJSON)),
        'pollutionDataTotals': ((json['pollutionDataTotals'] as Array<any>).map(PollutionEntryFromJSON)),
    };
}

export function RoutePointsAirQualityToJSON(value?: RoutePointsAirQuality | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}


