import { createStyles } from "@material-ui/core";
import { globalStyles } from "../../styles/globalStyles";

export const styles = createStyles({
  ...globalStyles,

  slider: {
    width: "90%",
    marginLeft: "5%",
    marginRight: "5%"
  },

  progressBarContainer: {
    width: "400px",
    height: "40px",
    margin: "0 auto",
    position: "relative",
    top: "50%",
    transform: "translateY(-50%)",
    borderRadius: "35px",
    overflow: "hidden"
  },

  pollutantName: {
    position: "absolute",
    top: 6,
    left: 20,
    color: "white",
    zIndex: 99
  },

  pollutionValue: {
    position: "absolute",
    top: 6,
    right: 20,
    color: "#82bac7",
    zIndex: 99
  },

  progressBarChild: {
    width: "100%",
    height: "100%"
  },

  progress: {
    color: "white",
    textAlign: "center",
    lineHeight: "75px",
    fontSize: "35px",
    fontFamily: "Segoe UI",
    animationDirection: "reverse"
  },

  shrinker: {
    backgroundColor: "#cacaca",
    position: "absolute",
    top: 0,
    right: 0,
    height: "100%"
  },
  
  timelapse: {
    animationName: "timelapse",
    animationFillMode: "forwards",
    animationDuration: "2s",
    animationTimingFunction: "cubic-bezier(.86, .05, .4, .96)"
  },

  recommendationLimit: {
    backgroundColor: "red",
    position: "absolute",
    top: 0,
    height: "100%",
    width: "2px",
    zIndex: 99
  }

});