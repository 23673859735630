import { createStyles } from "@material-ui/core";
import { globalStyles } from "../../../styles/globalStyles";
import theme from "../../../theme/theme";

export const styles = createStyles({
  ...globalStyles,

  saveButton: {
    ...globalStyles.primaryButton,
    marginLeft: theme.spacing(1.5),
    borderRadius: theme.spacing(1)
  },

  deleteDraftButton: {
    ...globalStyles.warningButton,
    borderRadius: theme.spacing(1)
  },

  managedItemContainer: {
    width: 880,
    padding: "8px 0px",
    [theme.breakpoints.down("lg")]: {
      width: 720
    },
    [theme.breakpoints.down("md")]: {
      width: 500
    }
  },

  managedItemTitle: {
    width: "100%",
    padding: `0px ${theme.spacing(4)}px`,
    marginTop: 0,
    height: 64
  },

  managedItemTitleText: {
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.light,
    fontWeight: 600
  },

  buttonContainer: {
    display: "flex",
    width: 320,
    marginLeft: "auto",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("lg")]: {
      width: 250
    }
  },

  managedItemDetail: {
    padding: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      padding: 0
    }
  },

  managedItemGeneralsContainer: {
    paddingLeft: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: 40,
    margin: `${theme.spacing(1.5)}px 0px`,
    justifyContent: "space-between"
  },

  deleteManagedItemButton: {
    "&:hover": {
      backgroundColor: "transparent"
    },
    marginLeft: theme.spacing(2)
  },

  dataGridContainer: {
    height: 320,
    width: "100%",
    position: "relative"
  },

  deletePollutantButtonContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    width: 120,
    height: 56,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: `0px ${theme.spacing(1)}px`,
    zIndex: 101
  },

  deletePollutantButton: {
    backgroundColor: theme.palette.warning.main,
    color: "#FFFFFF",
    maxWidth: "80%",
    borderRadius: 5
  },

  newPenaltyContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    padding: `0px ${theme.spacing(1)}px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },

  newPollutantSelect: {
    minWidth: 250
  },

  addPenaltyButton: {
    marginLeft: theme.spacing(6),
    "&:hover": {
      backgroundColor: "transparent"
    }
  }
  
});