/* tslint:disable */
/* eslint-disable */
/**
 * Megasense data portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Route,
    RouteFromJSON,
    RouteToJSON,
    RouteHistoryType,
    RouteHistoryTypeFromJSON,
    RouteHistoryTypeToJSON,
    TransportationType,
    TransportationTypeFromJSON,
    TransportationTypeToJSON,
} from '../models';

export interface CreateRouteRequest {
    route: Route;
}

export interface DeleteRouteRequest {
    routeId: string;
}

export interface FindRouteRequest {
    routeId: string;
}

export interface ListRoutesRequest {
    routeHistoryType?: RouteHistoryType;
    transportationType?: TransportationType;
    accessRights?: ListRoutesAccessRightsEnum;
}

/**
 * 
 */
export class RoutesApi extends runtime.BaseAPI {

    /**
     * Creates a route
     */
    async createRouteRaw(requestParameters: CreateRouteRequest): Promise<runtime.ApiResponse<Route>> {
        if (requestParameters.route === null || requestParameters.route === undefined) {
            throw new runtime.RequiredError('route','Required parameter requestParameters.route was null or undefined when calling createRoute.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/routes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RouteToJSON(requestParameters.route),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RouteFromJSON(jsonValue));
    }

    /**
     * Creates a route
     */
    async createRoute(requestParameters: CreateRouteRequest): Promise<Route> {
        const response = await this.createRouteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes a route
     */
    async deleteRouteRaw(requestParameters: DeleteRouteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.routeId === null || requestParameters.routeId === undefined) {
            throw new runtime.RequiredError('routeId','Required parameter requestParameters.routeId was null or undefined when calling deleteRoute.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/routes/{routeId}`.replace(`{${"routeId"}}`, encodeURIComponent(String(requestParameters.routeId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a route
     */
    async deleteRoute(requestParameters: DeleteRouteRequest): Promise<void> {
        await this.deleteRouteRaw(requestParameters);
    }

    /**
     * Finds a route
     */
    async findRouteRaw(requestParameters: FindRouteRequest): Promise<runtime.ApiResponse<Route>> {
        if (requestParameters.routeId === null || requestParameters.routeId === undefined) {
            throw new runtime.RequiredError('routeId','Required parameter requestParameters.routeId was null or undefined when calling findRoute.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/routes/{routeId}`.replace(`{${"routeId"}}`, encodeURIComponent(String(requestParameters.routeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RouteFromJSON(jsonValue));
    }

    /**
     * Finds a route
     */
    async findRoute(requestParameters: FindRouteRequest): Promise<Route> {
        const response = await this.findRouteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists all routes
     */
    async listRoutesRaw(requestParameters: ListRoutesRequest): Promise<runtime.ApiResponse<Array<Route>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.routeHistoryType !== undefined) {
            queryParameters['routeHistoryType'] = requestParameters.routeHistoryType;
        }

        if (requestParameters.transportationType !== undefined) {
            queryParameters['transportationType'] = requestParameters.transportationType;
        }

        if (requestParameters.accessRights !== undefined) {
            queryParameters['accessRights'] = requestParameters.accessRights;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/routes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RouteFromJSON));
    }

    /**
     * Lists all routes
     */
    async listRoutes(requestParameters: ListRoutesRequest): Promise<Array<Route>> {
        const response = await this.listRoutesRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum ListRoutesAccessRightsEnum {
    PUBLICONLY = 'PUBLIC_ONLY',
    PRIVATEONLY = 'PRIVATE_ONLY'
}
