import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Toolbar, Typography } from "@material-ui/core";
import React from "react";
import { Pollutant } from "../../generated/client";
import strings from "../../localization/strings";
import LogoIcon from "../../resources/svg/logo-icon";

/**
 * Interface describing component props
 */
interface Props {
  pollutants?: Pollutant[];
  selectedPollutant?: Pollutant;
  showControl?: boolean;
  onPollutantSelect: (pollutant: Pollutant) => void;
}

/**
 * Interface describing component state
 */
interface State {
}

/**
 * Component for selecting from pollutant listing
 */
class PollutantSelection extends React.Component<Props, State> {

  /**
   * Component constructor
   * 
   * @param props
   */
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  /**
   * Component render method
   */
  public render = () => {
    const { pollutants, showControl } = this.props;

    if (!pollutants || !showControl) {
      return (
        <></>
      );
    }

    return (
      <>
        <Toolbar>
          <Typography variant="h2">{ strings.pollutantControl.pollutant }</Typography>
        </Toolbar>
        <List style={{ paddingTop: 0 }}>
          { this.renderListItems() }
        </List>
      </>
    );
  };

  /**
   * Renders a pollutant list
   */
  private renderListItems = () => {
    const { pollutants, onPollutantSelect } = this.props;

    return pollutants?.map(pollutant => {
      return (
        <ListItem button key={ pollutant.pollutantName } onClick={ () => onPollutantSelect(pollutant) }>
          <ListItemAvatar>
            <Avatar>
              <LogoIcon htmlColor="#fff" fontSize="small"/>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={ pollutant.displayName }
          />
        </ListItem>
      );
    });
  };

}

export default PollutantSelection;