/* tslint:disable */
/* eslint-disable */
/**
 * Megasense data portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    FavouriteLocation,
    FavouriteLocationFromJSON,
    FavouriteLocationToJSON,
} from '../models';

export interface CreateUserFavouriteLocationRequest {
    favouriteLocation: FavouriteLocation;
}

export interface DeleteUserFavouriteLocationRequest {
    favouriteId: string;
}

export interface UpdateUserFavouriteLocationRequest {
    favouriteLocation: FavouriteLocation;
    favouriteId: string;
}

/**
 * 
 */
export class FavouriteLocationsApi extends runtime.BaseAPI {

    /**
     * Creates a new user favourite location
     */
    async createUserFavouriteLocationRaw(requestParameters: CreateUserFavouriteLocationRequest): Promise<runtime.ApiResponse<FavouriteLocation>> {
        if (requestParameters.favouriteLocation === null || requestParameters.favouriteLocation === undefined) {
            throw new runtime.RequiredError('favouriteLocation','Required parameter requestParameters.favouriteLocation was null or undefined when calling createUserFavouriteLocation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/favourites`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FavouriteLocationToJSON(requestParameters.favouriteLocation),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FavouriteLocationFromJSON(jsonValue));
    }

    /**
     * Creates a new user favourite location
     */
    async createUserFavouriteLocation(requestParameters: CreateUserFavouriteLocationRequest): Promise<FavouriteLocation> {
        const response = await this.createUserFavouriteLocationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes an user favourite location
     */
    async deleteUserFavouriteLocationRaw(requestParameters: DeleteUserFavouriteLocationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.favouriteId === null || requestParameters.favouriteId === undefined) {
            throw new runtime.RequiredError('favouriteId','Required parameter requestParameters.favouriteId was null or undefined when calling deleteUserFavouriteLocation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/favourites/{favouriteId}`.replace(`{${"favouriteId"}}`, encodeURIComponent(String(requestParameters.favouriteId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes an user favourite location
     */
    async deleteUserFavouriteLocation(requestParameters: DeleteUserFavouriteLocationRequest): Promise<void> {
        await this.deleteUserFavouriteLocationRaw(requestParameters);
    }

    /**
     * Gets user favourite locations
     */
    async listUserFavouriteLocationsRaw(): Promise<runtime.ApiResponse<Array<FavouriteLocation>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/favourites`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FavouriteLocationFromJSON));
    }

    /**
     * Gets user favourite locations
     */
    async listUserFavouriteLocations(): Promise<Array<FavouriteLocation>> {
        const response = await this.listUserFavouriteLocationsRaw();
        return await response.value();
    }

    /**
     * Updates details for an user favourite location
     */
    async updateUserFavouriteLocationRaw(requestParameters: UpdateUserFavouriteLocationRequest): Promise<runtime.ApiResponse<FavouriteLocation>> {
        if (requestParameters.favouriteLocation === null || requestParameters.favouriteLocation === undefined) {
            throw new runtime.RequiredError('favouriteLocation','Required parameter requestParameters.favouriteLocation was null or undefined when calling updateUserFavouriteLocation.');
        }

        if (requestParameters.favouriteId === null || requestParameters.favouriteId === undefined) {
            throw new runtime.RequiredError('favouriteId','Required parameter requestParameters.favouriteId was null or undefined when calling updateUserFavouriteLocation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/favourites/{favouriteId}`.replace(`{${"favouriteId"}}`, encodeURIComponent(String(requestParameters.favouriteId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FavouriteLocationToJSON(requestParameters.favouriteLocation),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FavouriteLocationFromJSON(jsonValue));
    }

    /**
     * Updates details for an user favourite location
     */
    async updateUserFavouriteLocation(requestParameters: UpdateUserFavouriteLocationRequest): Promise<FavouriteLocation> {
        const response = await this.updateUserFavouriteLocationRaw(requestParameters);
        return await response.value();
    }

}
