/* tslint:disable */
/* eslint-disable */
/**
 * Megasense data portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Time unit for passive exposure measurement
 * @export
 * @enum {string}
 */
export enum PassiveExposureMeasurementTimeUnit {
    MIN15 = 'MIN15',
    HOUR = 'HOUR',
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    YEAR = 'YEAR'
}

export function PassiveExposureMeasurementTimeUnitFromJSON(json: any): PassiveExposureMeasurementTimeUnit {
    return PassiveExposureMeasurementTimeUnitFromJSONTyped(json, false);
}

export function PassiveExposureMeasurementTimeUnitFromJSONTyped(json: any, ignoreDiscriminator: boolean): PassiveExposureMeasurementTimeUnit {
    return json as PassiveExposureMeasurementTimeUnit;
}

export function PassiveExposureMeasurementTimeUnitToJSON(value?: PassiveExposureMeasurementTimeUnit | null): any {
    return value as any;
}

