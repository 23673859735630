/* tslint:disable */
/* eslint-disable */
/**
 * Megasense data portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Pollutant,
    PollutantFromJSON,
    PollutantToJSON,
} from '../models';

export interface DeletePollutantRequest {
    pollutantId: string;
}

export interface FindPollutantRequest {
    pollutantId: string;
}

export interface GetPollutantsRequest {
    pollutantName?: string;
}

export interface UpdatePollutantRequest {
    pollutant: Pollutant;
    pollutantId: string;
}

/**
 * 
 */
export class PollutantsApi extends runtime.BaseAPI {

    /**
     * Deletes pollutant
     */
    async deletePollutantRaw(requestParameters: DeletePollutantRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.pollutantId === null || requestParameters.pollutantId === undefined) {
            throw new runtime.RequiredError('pollutantId','Required parameter requestParameters.pollutantId was null or undefined when calling deletePollutant.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/pollutants/{pollutantId}`.replace(`{${"pollutantId"}}`, encodeURIComponent(String(requestParameters.pollutantId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes pollutant
     */
    async deletePollutant(requestParameters: DeletePollutantRequest): Promise<void> {
        await this.deletePollutantRaw(requestParameters);
    }

    /**
     * Finds a pollutant
     */
    async findPollutantRaw(requestParameters: FindPollutantRequest): Promise<runtime.ApiResponse<Pollutant>> {
        if (requestParameters.pollutantId === null || requestParameters.pollutantId === undefined) {
            throw new runtime.RequiredError('pollutantId','Required parameter requestParameters.pollutantId was null or undefined when calling findPollutant.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/pollutants/{pollutantId}`.replace(`{${"pollutantId"}}`, encodeURIComponent(String(requestParameters.pollutantId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PollutantFromJSON(jsonValue));
    }

    /**
     * Finds a pollutant
     */
    async findPollutant(requestParameters: FindPollutantRequest): Promise<Pollutant> {
        const response = await this.findPollutantRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists pollutants
     */
    async getPollutantsRaw(requestParameters: GetPollutantsRequest): Promise<runtime.ApiResponse<Array<Pollutant>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pollutantName !== undefined) {
            queryParameters['pollutantName'] = requestParameters.pollutantName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/pollutants`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PollutantFromJSON));
    }

    /**
     * Lists pollutants
     */
    async getPollutants(requestParameters: GetPollutantsRequest): Promise<Array<Pollutant>> {
        const response = await this.getPollutantsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates pollutant
     */
    async updatePollutantRaw(requestParameters: UpdatePollutantRequest): Promise<runtime.ApiResponse<Pollutant>> {
        if (requestParameters.pollutant === null || requestParameters.pollutant === undefined) {
            throw new runtime.RequiredError('pollutant','Required parameter requestParameters.pollutant was null or undefined when calling updatePollutant.');
        }

        if (requestParameters.pollutantId === null || requestParameters.pollutantId === undefined) {
            throw new runtime.RequiredError('pollutantId','Required parameter requestParameters.pollutantId was null or undefined when calling updatePollutant.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/pollutants/{pollutantId}`.replace(`{${"pollutantId"}}`, encodeURIComponent(String(requestParameters.pollutantId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PollutantToJSON(requestParameters.pollutant),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PollutantFromJSON(jsonValue));
    }

    /**
     * Updates pollutant
     */
    async updatePollutant(requestParameters: UpdatePollutantRequest): Promise<Pollutant> {
        const response = await this.updatePollutantRaw(requestParameters);
        return await response.value();
    }

}
