import { createStyles } from "@material-ui/core";
import theme from "../../theme/theme";

export default createStyles({

  homeAddressInput: {
    "& .MuiInput-underline:after": {
      borderBottom: "1px solid #000"
    },
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid #000"
    },
    marginTop: theme.spacing(2)
  }

});