/* tslint:disable */
/* eslint-disable */
/**
 * Megasense data portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Route saving type
 * @export
 * @enum {string}
 */
export enum RouteHistoryType {
    FAVORITE = 'FAVORITE',
    HISTORY = 'HISTORY'
}

export function RouteHistoryTypeFromJSON(json: any): RouteHistoryType {
    return RouteHistoryTypeFromJSONTyped(json, false);
}

export function RouteHistoryTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RouteHistoryType {
    return json as RouteHistoryType;
}

export function RouteHistoryTypeToJSON(value?: RouteHistoryType | null): any {
    return value as any;
}

