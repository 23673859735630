import { createStyles } from "@material-ui/core";
import Color from "color";
import theme from "../../../theme/theme";

export const styles = createStyles({
  drawer: {
    width: 320
  },

  selectEmpty: {
    marginTop: theme.spacing(2)
  },

  chartContainerStyling: {
    width: "100%",
    height: 550
  },

  scopeSelector: {
    width: 200,
    marginRight: theme.spacing(2),
    "& .MuiOutlinedInput-root": {
      borderRadius: 30,
      "& .MuiSelect-root": {
        display: "flex",
        justifyContent: "center",
        color: "#682965",
        fontWeight: "600",
        padding: 14
      },
      "& .MuiSvgIcon-root": {
        color: theme.palette.primary.main
      }
    }
  },

  iconButton: {
    backgroundColor: "transparent !important"
  },

  notchedOutline: {
    borderColor: `${theme.palette.primary.main} !important`
  },

  pollutantToggleButton: {
    borderRadius: 30,
    color: "#fff !important",
    textTransform: "none",
    borderColor: "#fff",
    "&.Mui-selected": {
      borderColor: "transparent !important",
      backgroundColor: `${theme.palette.primary.main} !important`,
      boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)"
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: 200,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },

  chartHeader: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },

  chartBody: {
    padding: theme.spacing(2),
    backgroundImage: `linear-gradient(${Color(theme.palette.primary.main).alpha(0.35).toString()}, ${Color(theme.palette.primary.main).alpha(0.75).toString()})`
  },

  chartBodyLoading: {
    minHeight: 300,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  drawerContainer: {
    marginTop: theme.spacing(10),
    display: "flex",
    justifyContent: "center"
  },

  pollutantDetailHeader: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    display: "flex",
    alignItems: "center"
  },

  pollutantDetailBody: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(4),
    backgroundImage: `linear-gradient(${Color(theme.palette.primary.main).alpha(0.35).toString()}, ${Color(theme.palette.primary.main).alpha(0.75).toString()})`
  },

  pollutantDetailBodyLoading: {
    minHeight: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  statisticsHeader: {
    fontSize: 28,
    fontWeight: 600,
    color: theme.palette.primary.main
  }

});