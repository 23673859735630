/* tslint:disable */
/* eslint-disable */
/**
 * Megasense data portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StripeProduct,
    StripeProductFromJSON,
    StripeProductToJSON,
    StripeProductPrice,
    StripeProductPriceFromJSON,
    StripeProductPriceToJSON,
} from '../models';

export interface ListStripeProductPricesRequest {
    stripeProductId: string;
}

/**
 * 
 */
export class StripeProductsApi extends runtime.BaseAPI {

    /**
     * Lists Stripe product prices
     */
    async listStripeProductPricesRaw(requestParameters: ListStripeProductPricesRequest): Promise<runtime.ApiResponse<Array<StripeProductPrice>>> {
        if (requestParameters.stripeProductId === null || requestParameters.stripeProductId === undefined) {
            throw new runtime.RequiredError('stripeProductId','Required parameter requestParameters.stripeProductId was null or undefined when calling listStripeProductPrices.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stripeProducts/{stripeProductId}/prices`.replace(`{${"stripeProductId"}}`, encodeURIComponent(String(requestParameters.stripeProductId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StripeProductPriceFromJSON));
    }

    /**
     * Lists Stripe product prices
     */
    async listStripeProductPrices(requestParameters: ListStripeProductPricesRequest): Promise<Array<StripeProductPrice>> {
        const response = await this.listStripeProductPricesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists Stripe products
     */
    async listStripeProductsRaw(): Promise<runtime.ApiResponse<Array<StripeProduct>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stripeProducts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StripeProductFromJSON));
    }

    /**
     * Lists Stripe products
     */
    async listStripeProducts(): Promise<Array<StripeProduct>> {
        const response = await this.listStripeProductsRaw();
        return await response.value();
    }

}
