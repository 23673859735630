/* tslint:disable */
/* eslint-disable */
/**
 * Megasense data portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Route step data that is built by Open Trip Planner
 * @export
 * @interface RouteStep
 */
export interface RouteStep {
    /**
     * Distance
     * @type {number}
     * @memberof RouteStep
     */
    distance?: number;
    /**
     * Latitude
     * @type {number}
     * @memberof RouteStep
     */
    lat?: number;
    /**
     * Longitude
     * @type {number}
     * @memberof RouteStep
     */
    lon?: number;
    /**
     * Street name
     * @type {string}
     * @memberof RouteStep
     */
    streetName?: string;
    /**
     * Relative direction
     * @type {string}
     * @memberof RouteStep
     */
    relativeDirection?: string;
    /**
     * Absolute direction
     * @type {string}
     * @memberof RouteStep
     */
    absoluteDirection?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RouteStep
     */
    stayOn?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RouteStep
     */
    area?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RouteStep
     */
    bogusName?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RouteStep
     */
    elevation?: string;
}

export function RouteStepFromJSON(json: any): RouteStep {
    return RouteStepFromJSONTyped(json, false);
}

export function RouteStepFromJSONTyped(json: any, ignoreDiscriminator: boolean): RouteStep {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'distance': !exists(json, 'distance') ? undefined : json['distance'],
        'lat': !exists(json, 'lat') ? undefined : json['lat'],
        'lon': !exists(json, 'lon') ? undefined : json['lon'],
        'streetName': !exists(json, 'streetName') ? undefined : json['streetName'],
        'relativeDirection': !exists(json, 'relativeDirection') ? undefined : json['relativeDirection'],
        'absoluteDirection': !exists(json, 'absoluteDirection') ? undefined : json['absoluteDirection'],
        'stayOn': !exists(json, 'stayOn') ? undefined : json['stayOn'],
        'area': !exists(json, 'area') ? undefined : json['area'],
        'bogusName': !exists(json, 'bogusName') ? undefined : json['bogusName'],
        'elevation': !exists(json, 'elevation') ? undefined : json['elevation'],
    };
}

export function RouteStepToJSON(value?: RouteStep | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'distance': value.distance,
        'lat': value.lat,
        'lon': value.lon,
        'streetName': value.streetName,
        'relativeDirection': value.relativeDirection,
        'absoluteDirection': value.absoluteDirection,
        'stayOn': value.stayOn,
        'area': value.area,
        'bogusName': value.bogusName,
        'elevation': value.elevation,
    };
}


