/* tslint:disable */
/* eslint-disable */
/**
 * Megasense data portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AirQuality,
    AirQualityFromJSON,
    AirQualityFromJSONTyped,
    AirQualityToJSON,
    PollutionEntry,
    PollutionEntryFromJSON,
    PollutionEntryFromJSONTyped,
    PollutionEntryToJSON,
} from './';

/**
 * Historical entry of the pollutants and exposure that the user encountered. Not requested in real-time but preserved in the database.
 * @export
 * @interface RoutePollutionExposureRecord
 */
export interface RoutePollutionExposureRecord {
    /**
     * 
     * @type {string}
     * @memberof RoutePollutionExposureRecord
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RoutePollutionExposureRecord
     */
    routeId?: string;
    /**
     * 
     * @type {Date}
     * @memberof RoutePollutionExposureRecord
     */
    startedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RoutePollutionExposureRecord
     */
    endedAt?: Date;
    /**
     * List of air quality entries for all the route points
     * @type {Array<AirQuality>}
     * @memberof RoutePollutionExposureRecord
     */
    pollutionValuesForRoute?: Array<AirQuality>;
    /**
     * List of pollution exposure entries that user breathed during travelling the route
     * @type {Array<PollutionEntry>}
     * @memberof RoutePollutionExposureRecord
     */
    pollutionDataTotals?: Array<PollutionEntry>;
}

export function RoutePollutionExposureRecordFromJSON(json: any): RoutePollutionExposureRecord {
    return RoutePollutionExposureRecordFromJSONTyped(json, false);
}

export function RoutePollutionExposureRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoutePollutionExposureRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'routeId': !exists(json, 'routeId') ? undefined : json['routeId'],
        'startedAt': !exists(json, 'startedAt') ? undefined : (new Date(json['startedAt'])),
        'endedAt': !exists(json, 'endedAt') ? undefined : (new Date(json['endedAt'])),
        'pollutionValuesForRoute': !exists(json, 'pollutionValuesForRoute') ? undefined : ((json['pollutionValuesForRoute'] as Array<any>).map(AirQualityFromJSON)),
        'pollutionDataTotals': !exists(json, 'pollutionDataTotals') ? undefined : ((json['pollutionDataTotals'] as Array<any>).map(PollutionEntryFromJSON)),
    };
}

export function RoutePollutionExposureRecordToJSON(value?: RoutePollutionExposureRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'routeId': value.routeId,
        'startedAt': value.startedAt === undefined ? undefined : (value.startedAt.toISOString()),
        'endedAt': value.endedAt === undefined ? undefined : (value.endedAt.toISOString()),
        'pollutionValuesForRoute': value.pollutionValuesForRoute === undefined ? undefined : ((value.pollutionValuesForRoute as Array<any>).map(AirQualityToJSON)),
        'pollutionDataTotals': value.pollutionDataTotals === undefined ? undefined : ((value.pollutionDataTotals as Array<any>).map(PollutionEntryToJSON)),
    };
}


