/* tslint:disable */
/* eslint-disable */
/**
 * Megasense data portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PollutantPenalty,
    PollutantPenaltyFromJSON,
    PollutantPenaltyFromJSONTyped,
    PollutantPenaltyToJSON,
    RoutingModeIcon,
    RoutingModeIconFromJSON,
    RoutingModeIconFromJSONTyped,
    RoutingModeIconToJSON,
} from './';

/**
 * detault routing mode
 * @export
 * @interface DefaultRoutingMode
 */
export interface DefaultRoutingMode {
    /**
     * 
     * @type {string}
     * @memberof DefaultRoutingMode
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DefaultRoutingMode
     */
    name: string;
    /**
     * 
     * @type {RoutingModeIcon}
     * @memberof DefaultRoutingMode
     */
    icon: RoutingModeIcon;
    /**
     * 
     * @type {Array<PollutantPenalty>}
     * @memberof DefaultRoutingMode
     */
    pollutantPenalties?: Array<PollutantPenalty>;
}

export function DefaultRoutingModeFromJSON(json: any): DefaultRoutingMode {
    return DefaultRoutingModeFromJSONTyped(json, false);
}

export function DefaultRoutingModeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DefaultRoutingMode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'icon': RoutingModeIconFromJSON(json['icon']),
        'pollutantPenalties': !exists(json, 'pollutantPenalties') ? undefined : ((json['pollutantPenalties'] as Array<any>).map(PollutantPenaltyFromJSON)),
    };
}

export function DefaultRoutingModeToJSON(value?: DefaultRoutingMode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'icon': RoutingModeIconToJSON(value.icon),
        'pollutantPenalties': value.pollutantPenalties === undefined ? undefined : ((value.pollutantPenalties as Array<any>).map(PollutantPenaltyToJSON)),
    };
}


