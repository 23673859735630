import { createStyles } from "@material-ui/core";
import { globalStyles } from "../../styles/globalStyles";

export const styles = createStyles({
  ...globalStyles,

  airQualityDataLoader: {
    display: "flex",
    flexDirection: "row",
    alignSelf: "center",
    margin: "20px"
  }

});