import React from "react";
import { Typography, withStyles, WithStyles } from "@material-ui/core";
import { styles } from "./pollutant-slider.styles";
import classNames from "classnames";
import { Pollutant } from "../../generated/client";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  pollutant: Pollutant;
  pollutionValue: number;
}

/**
 * Interface describing component state
 */
interface State {}

/**
 * Component which displays air quality per pollutant in sliders list
 */
class PollutantSlider extends React.Component<Props, State> {

  /**
   * Component constructor
   *
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  
  /**
   * Render method to display air quality for route sliders
   */
  public render = () => {
    const {
      classes,
      pollutant,
      pollutionValue
    } = this.props;

    const pollutantLimit = pollutant.limit || 0;
    const pollutantRecommendation = pollutant.recommendation || 0;

    const maxPollutionValue = Math.max(pollutionValue, pollutantLimit);
    const pollutionRangePercentage = ((pollutantLimit - pollutantRecommendation) / maxPollutionValue) * 100;
    const pollutionPercentage = (pollutionValue / maxPollutionValue) * 100;
    const inverseWidthPercentage = 100 - PollutantSlider.percentageInRange(pollutionPercentage);
    const recommendationPercentage = PollutantSlider.percentageInRange((pollutantRecommendation / maxPollutionValue) * 100);
    const exposureValue = pollutionValue > 10 ?
      Math.round(pollutionValue) :
      Math.round((pollutionValue + Number.EPSILON) * 1000000) / 1000000;

    return (
      <div className={ classes.progressBarContainer }>
        <Typography className={ classes.pollutantName }>
          { pollutant.displayName }
        </Typography>
        <Typography className={ classes.pollutionValue }>
          { `${exposureValue} ${pollutant.unit}` }
        </Typography>
        <div
          className={ classes.recommendationLimit }
          style={{ left: `${recommendationPercentage}%` }}
        />
        <div
          className={ classNames(classes.shrinker, classes.timelapse) }
          style={{ width: `${inverseWidthPercentage}%` }}
        />
        <div
          style={{
            background: `linear-gradient(to right,
              #82bac7 ${recommendationPercentage}%,
              #b7adb8 ${recommendationPercentage + (pollutionRangePercentage * 0.45)}%,
              #ff3964 ${recommendationPercentage + pollutionRangePercentage}%)`
          }}
          className={ classNames(classes.progressBarChild, classes.progress) }
        />
      </div>
    );
  };

  /**
   * Make percentage stay in range  
   * 
   * @param number Percentage value
   * @returns Unified percentage value, within [0, 100]
   */
  static percentageInRange = (percentage: number) => {
    return Math.max(Math.min(percentage, 100), 0);
  };

}

export default withStyles(styles)(PollutantSlider);