/* tslint:disable */
/* eslint-disable */
/**
 * Megasense data portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Status where NOT_INITIATED is the initial status which is not yet set up in payment system, ACTIVE means subscription with functional payments, NOT_PAID is status where payments are not working, ENDED is for cancelled and unpaid subscriptions
 * @export
 * @enum {string}
 */
export enum StripeSubscriptionStatus {
    NOTINITIATED = 'NOT_INITIATED',
    ACTIVE = 'ACTIVE',
    NOTPAID = 'NOT_PAID',
    ENDED = 'ENDED'
}

export function StripeSubscriptionStatusFromJSON(json: any): StripeSubscriptionStatus {
    return StripeSubscriptionStatusFromJSONTyped(json, false);
}

export function StripeSubscriptionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): StripeSubscriptionStatus {
    return json as StripeSubscriptionStatus;
}

export function StripeSubscriptionStatusToJSON(value?: StripeSubscriptionStatus | null): any {
    return value as any;
}

