/* tslint:disable */
/* eslint-disable */
/**
 * Megasense data portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PollutantRequestParameter,
    PollutantRequestParameterFromJSON,
    PollutantRequestParameterFromJSONTyped,
    PollutantRequestParameterToJSON,
} from './';

/**
 * 
 * @export
 * @interface Pollutant
 */
export interface Pollutant {
    /**
     * 
     * @type {string}
     * @memberof Pollutant
     */
    id?: string;
    /**
     * Pollutant reference name for request parameters
     * @type {string}
     * @memberof Pollutant
     */
    pollutantName: string;
    /**
     * Displayed name for a pollutant
     * @type {string}
     * @memberof Pollutant
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof Pollutant
     */
    unit: string;
    /**
     * Pollutant variable name for NetCDF file indexing
     * @type {string}
     * @memberof Pollutant
     */
    variableName: string;
    /**
     * Maximum allowed concentration of pollutant that user can be exposed to
     * @type {number}
     * @memberof Pollutant
     */
    limit?: number;
    /**
     * Recommended concentration of pollutant that user can be exposed to
     * @type {number}
     * @memberof Pollutant
     */
    recommendation?: number;
    /**
     * Recommended max exposure for 15 minutes (total sum of consumed pollutant)
     * @type {number}
     * @memberof Pollutant
     */
    recommendationExposure15Minutes?: number;
    /**
     * Limit max exposure for 15 minutes (total sum of consumed pollutant)
     * @type {number}
     * @memberof Pollutant
     */
    limitExposure15Minutes?: number;
    /**
     * Limit of pollution level that will be used for map overlay styling
     * @type {number}
     * @memberof Pollutant
     */
    pollutionOverlayLimit?: number;
    /**
     * 
     * @type {PollutantRequestParameter}
     * @memberof Pollutant
     */
    requestParameters: PollutantRequestParameter;
}

export function PollutantFromJSON(json: any): Pollutant {
    return PollutantFromJSONTyped(json, false);
}

export function PollutantFromJSONTyped(json: any, ignoreDiscriminator: boolean): Pollutant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'pollutantName': json['pollutantName'],
        'displayName': json['displayName'],
        'unit': json['unit'],
        'variableName': json['variableName'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'recommendation': !exists(json, 'recommendation') ? undefined : json['recommendation'],
        'recommendationExposure15Minutes': !exists(json, 'recommendationExposure15Minutes') ? undefined : json['recommendationExposure15Minutes'],
        'limitExposure15Minutes': !exists(json, 'limitExposure15Minutes') ? undefined : json['limitExposure15Minutes'],
        'pollutionOverlayLimit': !exists(json, 'pollutionOverlayLimit') ? undefined : json['pollutionOverlayLimit'],
        'requestParameters': PollutantRequestParameterFromJSON(json['requestParameters']),
    };
}

export function PollutantToJSON(value?: Pollutant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'pollutantName': value.pollutantName,
        'displayName': value.displayName,
        'unit': value.unit,
        'variableName': value.variableName,
        'limit': value.limit,
        'recommendation': value.recommendation,
        'recommendationExposure15Minutes': value.recommendationExposure15Minutes,
        'limitExposure15Minutes': value.limitExposure15Minutes,
        'pollutionOverlayLimit': value.pollutionOverlayLimit,
        'requestParameters': PollutantRequestParameterToJSON(value.requestParameters),
    };
}


