import LocalizedStrings, { LocalizedStringsMethods } from "localized-strings";
import fi from "./fi.json";
import en from "./en.json";

/**
 * Interface describing localized strings
 */
export interface IStrings extends LocalizedStringsMethods {

  /**
   * Translations for authentication
   */
  auth: {
    login: string;
    logout: string;
    register: string;
    guestUser: string;
    name: string;
    email: string;
    password: string;
    repeatPassword: string;
    passwordCheckFail: string;
    passwordTooShort: string;
    passwordNoNumber: string;
  };

  /**
   * Translations for welcome screen
   */
  welcome: {
    hello: string;
    goodMorning: string;
    goodAfternoon: string;
    goodEvening: string;
  };

  /**
   * Translations for header
   */
  header: {
    statistics: string;
    map: string;
    settings: string;
    management: string;
    about: string;
    logoText: string;
  };

  /**
   * Translations for timepicker
   */
  timePicker: {
    today: string;
    cancel: string;
    clear: string;
  };

  /**
   * Translations for error dialog
   */
  errorDialog: {
    title: string;
    reloadPage: string;
    unsavedContents: string;
    reportIssue: string;
    technicalDetails: string;
    time: string;
    url: string;
    errorMessage: string;
    close: string;
    reload: string;
    notice: string;
  };

  /**
   * Translations for managed item accordion
   */
  managedItemAccordion: {
    deleteItem: string;
    pollutantName: string;
    pollutantPenalty: string;
    pollutantThreshold: string;
    newPenalty: string;
    newPenaltyPollutantName: string;
  };

  /**
   * Translations for routes
   */
  routes: {
    deleteRoute: string;
    savedRoutes: string;
    saveRoute: string;
    findRoute: string;
    deleteDialog: string;
    deleteButton: string;
    cancelButton: string;
    routeNameInput: string;
    navigated: string;
  };

  /**
   * Translations for places
   */
  locations: {
    deleteLocation: string;
    savedLocations: string;
    saveLocation: string;
    findLocation: string;
    duplicateLocation: string;
    duplicateLocationWarning: string;
  };

  /**
   * Translations for map screen
   */
  map: {
    myLocation: string;
    zoomIn: string;
    zoomOut: string;
    showDataOverlay: string;
    showMap: string;
  };
  
  /**
   * Translations for statistics screen
   */
  statistics: {
    title: string;
    selectPollution: string;
    selectTimeRange: string;
    scope: {
      day: string;
      week: string;
      month: string;
      year: string;
    };
    exposure: {
      day: string;
      week: string;
      month: string;
      year: string;
    };
    week: string;
    carbonMonoxide: string;
    ozone: string;
    nitrogenDioxide: string;
    sulfurDioxide: string;
  };

  /**
   * Translations for mangement screen
   */
  management: {
    title: string;
    navigation: string;
    medicalConditions: {
      title: string;
      refresh: string;
      approved: string;
      searchHint: string;
      newCondition: string;
      medicalConditionName: string;
      dialogs: {
        deleteDraft: {
          title: string;
          text: string;
        };
        createNewCondition: {
          title: string;
          text: string;
          newConditionName: string;
        };
        deleteCondition: {
          title: string;
          text: string;
        };
      }
    };
    defaultRoutingModes: {
      title: string;
      icon: string;
      newRoutingMode: string;
      routingModeName: string;
      dialogs: {
        deleteDraft: {
          title: string;
          text: string;
        };
        createNewRoutingMode: {
          title: string;
          text: string;
          newRoutingModeName: string;
        };
        deleteRoutingMode: {
          title: string;
          text: string;
        };
      }
    }
    pollutantLimit: {
      title: string;
      save: string;
      limit: string;
      recommendation: string;
      recommendationPercentage: string;
      recommendationPercentageHint: string;
      pollutionOverlayLimit: string;
    }
  };

  /**
   * Translations for about screen
   */
  aboutScreen: {
    title: string;
    subTitle: string;
    descriptionText: string;
  };
  
  to: string;
  from: string;
  departureTime: string;
  departureDate: string;
  savedRoutes: string;
  savedRoutesTo: string;
  savedRoutesFrom: string;
  savedRoutesSavedText: string;
  savedLocations: string;
  savedLocationsName: string;
  savedLocationsLatitude: string;
  savedLocationsLongtitude: string;
  savedLocationsSavedText: string;
  deleteConfirm: string;
  confirmTimeRange: string;
  selectPollution: string;
  weeklyExposure: string,
  monthlyExposure: string,
  annualExposure: string
  displayTables: string;
  selectTime: string;
  airQualityMode: string;
  movementOptions: string;
  walking: string;
  wheelerchair: string;
  viewRoute: string;
  viewLocation: string;
  deleteRoute: string;
  deleteLocation: string;
  collapseMenuText: string;
  user: string;
  showMore: string;
  showLess: string;
  airQuality: string;

  pollutants: {
    PM25: string;
    PM10: string;
    ozone: string;
    nitrogenOxide: string;
    sulfurOxide: string;
    carbonMonoxide: string;
  };

  common: {
    save: string;
    cancel: string;
    close: string;
    reset: string;
    yes: string;
    remove: string;
    confirm: string;
  };

  /**
   * Translations for settings drawer
   */
  settingsDrawer: {
    title: string;
    homeAddress: {
      title: string;
      description: string;
      streetAddress: string;
      city: string;
      zipCode: string;
      country: string;
    };
    transportation: {
      title: string;
      description: string;
      walking: string;
      biking: string;
      accessibility: string;
    };
    medicalCondition: {
      title: string;
      description: string;
    };
    custom: string;
    descriptionCustom: string;
    changeUserData: string;
    deleteAccount: string;
    deleteAccountDialogTitle: string;
    deleteAccountDialogText: string;
  };

  units: {
    hours: string;
  };

  pollutantControl: {
    pollutant: string;
    zScore: string;
  }
}

const strings: IStrings = new LocalizedStrings({
  fi: fi,
  en: en
});

export default strings;