/**
 * Utility class for password
 */
export default class PasswordUtils {

  /**
   * Check for password repeat
   * 
   * @param password password string
   * @param passwordRepeat password 
   * @return check result
   */
  public static passwordRepeatCheck = (password: string, passwordRepeat: string): boolean => {
    return password === passwordRepeat;
  };

  /**
   * Check for password length
   * 
   * @param password password string
   * @param length password length
   * @return check result
   */
  public static passwordLengthCheck = (password: string, length: number): boolean => {
    return password.length >= length;
  };

  /**
   * Check for password containing numbers
   * 
   * @param password password string
   * @return check result
   */
  public static passwordContainsNumber = (password: string): boolean => {
    return /\d/.test(password);
  };

}