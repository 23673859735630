/* tslint:disable */
/* eslint-disable */
/**
 * Megasense data portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FavouriteLocation,
    FavouriteLocationFromJSON,
    FavouriteLocationFromJSONTyped,
    FavouriteLocationToJSON,
    PollutantPenalty,
    PollutantPenaltyFromJSON,
    PollutantPenaltyFromJSONTyped,
    PollutantPenaltyToJSON,
    StripeSubscriptionStatus,
    StripeSubscriptionStatusFromJSON,
    StripeSubscriptionStatusFromJSONTyped,
    StripeSubscriptionStatusToJSON,
    TransportationType,
    TransportationTypeFromJSON,
    TransportationTypeFromJSONTyped,
    TransportationTypeToJSON,
} from './';

/**
 * User object
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    readonly id?: string;
    /**
     * User name
     * @type {string}
     * @memberof User
     */
    name: string;
    /**
     * User family name
     * @type {string}
     * @memberof User
     */
    surname?: string;
    /**
     * User email
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    password?: string;
    /**
     * 
     * @type {FavouriteLocation}
     * @memberof User
     */
    homeLocation?: FavouriteLocation;
    /**
     * 
     * @type {FavouriteLocation}
     * @memberof User
     */
    workLocation?: FavouriteLocation;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    showMobileWelcomeScreen: boolean;
    /**
     * user's personal pollutant penalty settings
     * @type {Array<PollutantPenalty>}
     * @memberof User
     */
    pollutantPenalties: Array<PollutantPenalty>;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    medicalConditions?: Array<string>;
    /**
     * 
     * @type {TransportationType}
     * @memberof User
     */
    transportPreference?: TransportationType;
    /**
     * 
     * @type {StripeSubscriptionStatus}
     * @memberof User
     */
    subscriptionStatus?: StripeSubscriptionStatus;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'email': json['email'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'homeLocation': !exists(json, 'homeLocation') ? undefined : FavouriteLocationFromJSON(json['homeLocation']),
        'workLocation': !exists(json, 'workLocation') ? undefined : FavouriteLocationFromJSON(json['workLocation']),
        'showMobileWelcomeScreen': json['showMobileWelcomeScreen'],
        'pollutantPenalties': ((json['pollutantPenalties'] as Array<any>).map(PollutantPenaltyFromJSON)),
        'medicalConditions': !exists(json, 'medicalConditions') ? undefined : json['medicalConditions'],
        'transportPreference': !exists(json, 'transportPreference') ? undefined : TransportationTypeFromJSON(json['transportPreference']),
        'subscriptionStatus': !exists(json, 'subscriptionStatus') ? undefined : StripeSubscriptionStatusFromJSON(json['subscriptionStatus']),
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'surname': value.surname,
        'email': value.email,
        'password': value.password,
        'homeLocation': FavouriteLocationToJSON(value.homeLocation),
        'workLocation': FavouriteLocationToJSON(value.workLocation),
        'showMobileWelcomeScreen': value.showMobileWelcomeScreen,
        'pollutantPenalties': ((value.pollutantPenalties as Array<any>).map(PollutantPenaltyToJSON)),
        'medicalConditions': value.medicalConditions,
        'transportPreference': TransportationTypeToJSON(value.transportPreference),
        'subscriptionStatus': StripeSubscriptionStatusToJSON(value.subscriptionStatus),
    };
}


