import { TransportationType } from "../generated/client";
import strings from "../localization/strings";

/**
 * Utility class for localizations
 */
export default class LocalizationUtils {

  /**
   * Gets localized route type string
   *
   * @param transportationType route type
   * @returns localized string
   */
  public static getLocalizedRouteType = (transportationType: TransportationType): string => ({
    [TransportationType.WALKING]: strings.settingsDrawer.transportation.walking,
    [TransportationType.BIKING]: strings.settingsDrawer.transportation.biking,
    [TransportationType.ACCESSIBILITY]: strings.settingsDrawer.transportation.accessibility
  })[transportationType];

}