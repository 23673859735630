import { Box, Paper, CircularProgress, Typography, TextField, Button, Tooltip } from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import Api from "../../../api";
import { Pollutant } from "../../../generated/client/models";
import strings from "../../../localization/strings";
import { ReduxActions, ReduxState } from "../../../store";
import theme from "../../../theme/theme";
import { CustomPollutant, NullableToken } from "../../../types";
import { styles } from "./management-pollutant-limit-view.styles";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  accessToken?: NullableToken;
  keycloak?: Keycloak.KeycloakInstance;
  setError: (error: any) => void;
}

/**
 * Interface describing component state
 */
interface State {
  customPollutants?: CustomPollutant[],
  isLoading: boolean,
}

/**
 * Component for management screen pollutant limit view
 */
class PollutantLimitView extends React.Component<Props, State> {

  /**
   * Component constructor
   * 
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  /**
   * Component life cycle method
   */
  public componentDidMount = async () => {
    this.loadData();
  };

  /**
   * Component render method
   */
  public render() {
    const { classes } = this.props;
    const { isLoading, customPollutants } = this.state;

    if (isLoading) {
      return (
        <Box className={ classes.loadingContainer }>
          <CircularProgress size={ 64 }/>
        </Box>
      );
    }

    if (!customPollutants) {
      return null;
    }

    return (
      <Box className={ classes.scrollablePollutantsContainer }>
        { customPollutants.map(customPollutant => this.renderPollutant(customPollutant)) }
      </Box>
    );
  }

  /**
   * Renders pollutant card
   * 
   * @param customPollutant pollutant to be rendered
   */
  private renderPollutant = (customPollutant: CustomPollutant) => {
    const { classes } = this.props;

    return (
      <Paper className={ classes.pollutantCard }>
        <Box className={ classes.pollutantCardTitle }>
          <Typography variant="h1" style={{ fontWeight: 600 }}>
            { customPollutant.pollutant.displayName }
          </Typography>
          <Typography
            variant="h2"
            style={{
              marginLeft: theme.spacing(2),
              color: "rgba(0,0,0,0.5)"
            }}
          >
            { `(${customPollutant.pollutant.unit})` }
          </Typography>
        </Box>
        <Box className={ classes.pollutantCardBody }>
          <Tooltip title={ strings.management.pollutantLimit.recommendationPercentageHint }>
            <TextField
              variant="standard"
              label={ strings.management.pollutantLimit.recommendationPercentage }
              value={ customPollutant.recommendationPercentage }
              onChange={ event => this.onRecommendationPercentageChange(event, customPollutant) }
              className={ classes.pollutantInput }
            />
          </Tooltip>
          <TextField
            variant="standard"
            type="number"
            label={ strings.management.pollutantLimit.recommendation }
            value={ customPollutant.pollutant.recommendation?.toString() || "" }
            onChange={ event => this.onRecommendationAmountChange(event, customPollutant) }
            className={ classes.pollutantInput }
          />
          <TextField
            variant="standard"
            type="number"
            label={ strings.management.pollutantLimit.limit }
            value={ customPollutant.pollutant.limit?.toString() || "" }
            onChange={ event => this.onLimitChange(event, customPollutant) }
            className={ classes.pollutantInput }
          />
          <TextField
            variant="standard"
            type="number"
            label={ strings.management.pollutantLimit.pollutionOverlayLimit }
            value={ customPollutant.pollutant.pollutionOverlayLimit?.toString() || "" }
            onChange={ event => this.onPollutionOverlayLimitChange(event, customPollutant) }
            className={ classes.pollutantInput }
          />
          <Button
            variant="contained"
            className={ classes.saveButton }
            onClick={ () => this.onSaveClick(customPollutant) }
          >
            { strings.management.pollutantLimit.save }
          </Button>
        </Box>
      </Paper>
    );
  };

  /**
   * Load medical condition data
   */
  private loadData = async () => {
    this.setState({
      isLoading: true
    });

    const { accessToken, setError } = this.props;

    try {
      await Api.getPollutantsApi(accessToken).getPollutants({ pollutantName: undefined })
        .then(pollutantsRaw => {
          const customPollutants: CustomPollutant[] = pollutantsRaw.map(
            pollutantRaw => {
              const recommendationPercentage = (pollutantRaw.limit && pollutantRaw.recommendation)
                ?
                PollutantLimitView.numberToPercentageConverter(pollutantRaw.recommendation, pollutantRaw.limit)
                :
                "";
      
              return {
                modified: false,
                recommendationPercentage: recommendationPercentage,
                pollutant: pollutantRaw
              };
            }
          );

          this.setState({
            customPollutants: customPollutants
          });
        });
    } catch (error) {
      setError(error);
    }

    this.setState({
      isLoading: false
    });
  };

  /**
   * Recommendation percentage input change handler
   * 
   * @param event React input change event
   * @param selectedCustomPollutant pollutant to be changed
   */
  private onRecommendationPercentageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, selectedCustomPollutant: CustomPollutant) => {
    const { customPollutants } = this.state;

    if (!customPollutants) {
      return;
    }

    const recommendationPercentage = event.target.value as string;

    const updatedCustomPollutant: CustomPollutant = {
      ...selectedCustomPollutant,
      recommendationPercentage: recommendationPercentage
    };

    if (selectedCustomPollutant.pollutant.limit && recommendationPercentage.match(/^\d+(?:\.\d+)?%$/)) {
      updatedCustomPollutant.modified = true;
      updatedCustomPollutant.pollutant.recommendation = PollutantLimitView.percentageToNumberConverter(recommendationPercentage, updatedCustomPollutant.pollutant.limit!);
    }

    this.setState({
      customPollutants: customPollutants.map(
        customPollutant =>
          (customPollutant.pollutant.id === updatedCustomPollutant.pollutant.id ? updatedCustomPollutant : customPollutant)
      )
    });
  };

  /**
   * Recommendation amount input change input handler
   * 
   * @param event React input change event
   * @param slectedCustomPollutant pollutant to be changed
   */
  private onRecommendationAmountChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, selectedCustomPollutant: CustomPollutant) => {
    const { customPollutants } = this.state;

    if (!customPollutants) {
      return;
    }

    const recommendationAmount = event.target.value as unknown as number || 0;

    const updatedPollutant: Pollutant = {
      ...selectedCustomPollutant.pollutant,
      recommendation: recommendationAmount
    };

    const recommendationPercentage = selectedCustomPollutant.pollutant.limit ? PollutantLimitView.numberToPercentageConverter(recommendationAmount, selectedCustomPollutant.pollutant.limit) : "";

    const updatedCustomPollutant: CustomPollutant = {
      recommendationPercentage: recommendationPercentage,
      modified: true,
      pollutant: updatedPollutant
    };

    this.setState({
      customPollutants: customPollutants.map(
        customPollutant =>
          (customPollutant.pollutant.id === updatedCustomPollutant.pollutant.id ? updatedCustomPollutant : customPollutant)
      )
    });
  };

  /**
   * Pollutant exposure limit change input handler
   * 
   * @param event React input change event
   * @param selectedCustomPollutant pollutant to be changed
   */
  private onLimitChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, selectedCustomPollutant: CustomPollutant) => {
    const { customPollutants } = this.state;

    if (!customPollutants) {
      return;
    }

    const limitAmount = event.target.value as unknown as number || 0;

    const updatedPollutant: Pollutant = {
      ...selectedCustomPollutant.pollutant,
      limit: limitAmount
    };

    const recommendationPercentage = selectedCustomPollutant.pollutant.recommendation ? PollutantLimitView.numberToPercentageConverter(selectedCustomPollutant.pollutant.recommendation, limitAmount) : "";

    const updatedCustomPollutant: CustomPollutant = {
      recommendationPercentage: recommendationPercentage,
      modified: true,
      pollutant: updatedPollutant
    };

    this.setState({
      customPollutants: customPollutants.map(
        customPollutant =>
          (customPollutant.pollutant.id === updatedCustomPollutant.pollutant.id ? updatedCustomPollutant : customPollutant)
      )
    });
  };

  /**
   * Recommendation amount input change input handler
   * 
   * @param event React input change event
   * @param slectedCustomPollutant pollutant to be changed
   */
  private onPollutionOverlayLimitChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, selectedCustomPollutant: CustomPollutant) => {
    const { customPollutants } = this.state;

    if (!customPollutants) {
      return;
    }

    const overlayLimitAmount = event.target.value as unknown as number || 0;
    console.log(`new value is ${overlayLimitAmount}`);
    const updatedPollutant: Pollutant = {
      ...selectedCustomPollutant.pollutant,
      pollutionOverlayLimit: overlayLimitAmount
    };

    const updatedCustomPollutant: CustomPollutant = {
      recommendationPercentage: selectedCustomPollutant.recommendationPercentage,
      modified: true,
      pollutant: updatedPollutant
    };

    this.setState({
      customPollutants: customPollutants.map(
        customPollutant =>
          (customPollutant.pollutant.id === updatedCustomPollutant.pollutant.id ? updatedCustomPollutant : customPollutant)
      )
    });
  };

  /**
   * Pollutant exposure limit change input handler
   * 
   * @param selectedCustomPollutant pollutant to be changed
   */
  private onSaveClick = async (selectedCustomPollutant: CustomPollutant) => {
    const { accessToken, setError } = this.props;
    const { customPollutants } = this.state;

    if (!customPollutants || !selectedCustomPollutant.pollutant.id) {
      return;
    }

    try {
      Api.getPollutantsApi(accessToken).updatePollutant({
        pollutantId: selectedCustomPollutant.pollutant.id,
        pollutant: selectedCustomPollutant.pollutant
      }).then(updatedPollutant => {
        const updatedCustomPollutant: CustomPollutant = {
          modified: false,
          recommendationPercentage: selectedCustomPollutant.recommendationPercentage,
          pollutant: updatedPollutant
        };

        this.setState({
          customPollutants: customPollutants.map(
            customPollutant =>
              (customPollutant.pollutant.id === updatedCustomPollutant.pollutant.id ? updatedCustomPollutant : customPollutant)
          )
        });
      });
    } catch (error) {
      setError(error);
    }
  };

  /**
   * Util method that rounds number to two decimals
   * 
   * @param number number to be rounded
   * @return rounded number
   */
  static toTwoDecimals = (number: number) => {
    return Math.round(number * 100) / 100;
  };

  /**
   * Util method converting number to percentage
   * 
   * @param recommendation recommendation value
   * @param limit limit value
   * @return converted percentage string
   */
  static numberToPercentageConverter = (recommendation: number, limit: number) => {
    return `${PollutantLimitView.toTwoDecimals((recommendation / limit) * 100).toString()}%`;
  };

  /**
   * Util method converting percentage to number
   * 
   * @param percentage percentage value
   * @param limit limit value
   * @return converted number
   */
  static percentageToNumberConverter = (percentage: string, limit: number) => {
    const fraction = parseFloat(percentage.split("%")[0]) * 0.01;
    return PollutantLimitView.toTwoDecimals(fraction * limit);
  };

}

/**
 * Redux mapper for mapping store state to component props
 * 
 * @param state store state
 */
export function mapStateToProps(state: ReduxState) {
  return {
    accessToken: state.auth.accessToken,
    keycloak: state.auth.keycloak
  };
}

/**
 * Redux mapper for mapping component dispatches 
 * 
 * @param dispatch dispatch method
 */
export function mapDispatchToProps(dispatch: Dispatch<ReduxActions>) {
  return {
  };
}

const Styled = withStyles(styles)(PollutantLimitView);

export default connect(mapStateToProps, mapDispatchToProps)(Styled);