import React, { Dispatch } from "react";
import { AppBar, Box, Button, IconButton, Toolbar, Typography, withStyles, WithStyles, Checkbox, Slider, FormGroup, FormControlLabel, Accordion, AccordionSummary, AccordionDetails, TextField } from "@material-ui/core";
import styles from "./settings.styles";
import CloseIcon from "@material-ui/icons/Close";
import strings from "../../localization/strings";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TimerIcon from "@material-ui/icons/Timer";
import EcoIcon from "@material-ui/icons/Eco";
import LogoIcon from "../../resources/svg/logo-icon";
import Api from "../../api";
import { ReduxActions, ReduxState } from "../../store";
import { NullableToken } from "../../types";
import { Address, MedicalCondition, TransportationType, User } from "../../generated/client/models";
import { connect } from "react-redux";
import LocalizationUtils from "../../utils/localization-utils";
import ConfirmDialog from "../generic/dialogs/confirm-dialog";
import theme from "../../theme/theme";
import { logout } from "../../actions/auth";
import MapUtils from "../../utils/map-utils";

const INITIAL_HOME_ADDRESS: Address = {
  city: "",
  country: "",
  postalCode: "",
  streetAddress: ""
};

const INITIAL_LONDON_CORRDINATES = [51.513297252679614, -0.08977827173628887];

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  accessToken?: NullableToken;
  keycloak?: Keycloak.KeycloakInstance;
  onSettingsCloseClick: () => void;
  onLogout: () => void;
}

/**
 * Interface describing component state
 */
interface State {
  medicalConditions: MedicalCondition[];
  modified: boolean;
  userSettings?: User;
  deletingAccount: boolean;
}

/**
 * Component for settings
 */
class Settings extends React.Component<Props, State> {

  /**
   * Component constructor
   * 
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      modified: false,
      medicalConditions: [],
      deletingAccount: false
    };
  }

  /**
   * Component life cycle method
   */
  public componentDidMount = async () => {
    this.loadData();
  };

  /**
   * Settings render method
   */
  public render = () => {
    const { onSettingsCloseClick } = this.props;
    const { modified } = this.state;

    return (
      <>
        <AppBar position="relative">
          <Toolbar>
            <Box
              display="flex"
              flex="1"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h5">{ strings.settingsDrawer.title }</Typography>
              <Box>
                <Button
                  disabled={ !modified }
                  color="inherit"
                  variant="text"
                  onClick={ this.onSaveClick }
                >
                  { strings.common.save }
                </Button>
                <IconButton
                  color="inherit"
                  onClick={ onSettingsCloseClick }
                >
                  <CloseIcon/>
                </IconButton>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        <Box style={{ overflow: "auto" }}>
          { this.renderAccordionMenu() }
        </Box>
        { this.renderDeleteAccountDialog() }
      </>
    );
  };

  /**
   * Renders settings accordion menu
   */
  private renderAccordionMenu = () => (
    <Box p={ 3 }>
      { this.renderAccordionMenuHomeAddress() }
      { this.renderAccordionMenuTransportation() }
      { this.renderAccordionMenuMedical() }
      { this.renderAccordionMenuCustom() }
      <Button
        fullWidth
        onClick={ this.onChangeAccountSettings }
        variant="text"
        color="primary"
        style={{ marginTop: theme.spacing(2) }}
      >
        { strings.settingsDrawer.changeUserData }
      </Button>
      <Button
        fullWidth
        onClick={ this.onDeleteAccount }
        variant="text"
        style={{ marginTop: theme.spacing(2), color: theme.palette.error.main }}
      >
        { strings.settingsDrawer.deleteAccount }
      </Button>
    </Box>
  );

  /**
   * Renders home address accordion menu item
   */
  private renderAccordionMenuHomeAddress = () => {
    const { classes } = this.props;
    const { userSettings } = this.state;

    if (!userSettings) {
      return;
    }

    return (
      <Accordion defaultExpanded={ true }>
        <AccordionSummary expandIcon={ <ExpandMoreIcon/> }>
          <Typography>{ strings.settingsDrawer.homeAddress.title }</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{ strings.settingsDrawer.homeAddress.description }</Typography>
          <FormGroup>
            <TextField
              name="streetAddress"
              variant="standard"
              className={ classes.homeAddressInput }
              label={ strings.settingsDrawer.homeAddress.streetAddress }
              value={ userSettings.homeLocation?.address?.streetAddress || "" }
              onChange={ this.onHomeAddressChange }
            />
            <TextField
              name="city"
              variant="standard"
              className={ classes.homeAddressInput }
              label={ strings.settingsDrawer.homeAddress.city }
              value={ userSettings.homeLocation?.address?.city || "" }
              onChange={ this.onHomeAddressChange }
            />
            <TextField
              name="postalCode"
              variant="standard"
              className={ classes.homeAddressInput }
              label={ strings.settingsDrawer.homeAddress.zipCode }
              value={ userSettings.homeLocation?.address?.postalCode || "" }
              onChange={ this.onHomeAddressChange }
            />
            <TextField
              name="country"
              variant="standard"
              className={ classes.homeAddressInput }
              label={ strings.settingsDrawer.homeAddress.country }
              value={ userSettings.homeLocation?.address?.country || "" }
              onChange={ this.onHomeAddressChange }
            />
          </FormGroup>
        </AccordionDetails>
      </Accordion>
    );
  };

  /**
   * Renders transportation accordion menu item
   */
  private renderAccordionMenuTransportation = () => (
    <Accordion defaultExpanded={ true }>
      <AccordionSummary expandIcon={ <ExpandMoreIcon/> }>
        <Typography>{ strings.settingsDrawer.transportation.title }</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{ strings.settingsDrawer.transportation.description }</Typography>
        <FormGroup>
          { Object.values(TransportationType).map(this.renderTransportationCheckbox) }
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  );

  /**
   * Renders medical conditions accordion menu item
   */
  private renderAccordionMenuMedical = () => {
    const { medicalConditions } = this.state;

    return (
      <Accordion defaultExpanded={ true }>
        <AccordionSummary expandIcon={ <ExpandMoreIcon/> }>
          <Typography>{ strings.settingsDrawer.medicalCondition.title }</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{ strings.settingsDrawer.medicalCondition.description }</Typography>
          <FormGroup>
            { medicalConditions.map(this.renderMedicalConditionCheckbox) }
          </FormGroup>
        </AccordionDetails>
      </Accordion>
    );
  };

  /**
   * Renders medical condition checkbox
   *
   * @param medicalCondition medical condition
   */
  private renderMedicalConditionCheckbox = (medicalCondition: MedicalCondition) => {
    const { userSettings } = this.state;

    if (!medicalCondition.id) {
      return null;
    }

    return (
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={ userSettings?.medicalConditions?.some(conditionId => conditionId === medicalCondition.id) }
            onChange={ this.onMedicalConditionCheck(medicalCondition.id) }
          />
        }
        label={ medicalCondition.conditionName }
        labelPlacement="start"
      />
    );
  };

  /**
   * Renders transportation check box
   *
   * @param transportationType route type
   */
  private renderTransportationCheckbox = (transportationType: TransportationType) => {
    const { userSettings } = this.state;

    return (
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={ userSettings?.transportPreference === transportationType }
            onChange={ this.onTransportationMethodCheck(transportationType) }
          />
        }
        label={ LocalizationUtils.getLocalizedRouteType(transportationType) }
        labelPlacement="start"
      />
    );
  };

  /**
   * Renders pollutants accordion menu item
   */
  private renderAccordionMenuCustom = () => (
    <Accordion>
      <AccordionSummary expandIcon={ <ExpandMoreIcon/> }>
        <Typography>{ strings.settingsDrawer.custom }</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{ strings.settingsDrawer.descriptionCustom }</Typography>
        { this.renderPollutantSlider(strings.pollutants.carbonMonoxide) }
        { this.renderPollutantSlider(strings.pollutants.ozone) }
        { this.renderPollutantSlider(strings.pollutants.sulfurOxide) }
        { this.renderPollutantSlider(strings.pollutants.nitrogenOxide) }
        { this.renderPollutantSlider(strings.pollutants.PM10) }
        { this.renderPollutantSlider(strings.pollutants.PM25) }
        <Button
          color="primary"
          variant="contained"
          fullWidth={ true }
        >
          { strings.common.save }
        </Button>
      </AccordionDetails>
    </Accordion>
  );

  /**
   * Renders pollutant slider
   *
   * @param label slider label string
   */
  private renderPollutantSlider = (label: string) => {
    const marks = [
      {
        value: 0,
        label: <TimerIcon color="primary"/>
      },
      {
        value: 50,
        label: <LogoIcon color="primary"/>
      },
      {
        value: 100,
        label: <EcoIcon color="primary"/>
      }
    ];
  
    return (
      <Box>
        <Typography>
          { label }
        </Typography>
        <Slider
          defaultValue={ 50 }
          step={ 50 }
          valueLabelDisplay="off"
          marks={ marks }
          track={ false }
        />
      </Box>
    );
  };

  /**
   * Renders delete account dialog
   */
  private renderDeleteAccountDialog = () => {
    const { deletingAccount } = this.state;
  
    return (
      <ConfirmDialog
        title={ strings.settingsDrawer.deleteAccountDialogTitle }
        text={ strings.settingsDrawer.deleteAccountDialogText }
        positiveButtonText={ strings.common.yes }
        cancelButtonText={ strings.common.cancel }
        dialogVisible={ deletingAccount }
        onDialogConfirm={ this.onDeleteAccountConfirm }
        onDialogCancel={ this.onDeleteAccountCancel }
      />
    );
  };

  /**
   * Loads user settings
   */
  private loadUserSettings = async () => {
    const { accessToken } = this.props;
    if (!accessToken?.userId) {
      return;
    }
    const userSettings = await Api.getUsersApi(accessToken).getUser({ userId: accessToken.userId });

    this.setState({ userSettings: userSettings });
  };

  /**
   * Loads medical conditions
   */
  private loadMedicalConditions = async () => {
    const { accessToken } = this.props;
    const medicalConditions = await Api.getMedicalConditionsApi(accessToken).getMedicalConditions();

    this.setState({
      medicalConditions: medicalConditions.filter(condition => condition.conditionApproved)
    });
  };

  /**
   * Loads data
   */
  private loadData = async () => {
    await this.loadUserSettings();
    await this.loadMedicalConditions();
  };

  /**
   * On save click handler
   */
  private onSaveClick = async () => {
    const { accessToken } = this.props;
    const { userSettings } = this.state;

    if (!userSettings || !accessToken?.userId) {
      return;
    }

    let updateData = userSettings;

    // Try to retrieve the coordinates of provided address
    if (userSettings.homeLocation?.address) {
      const coordinates = await MapUtils.getLocation(userSettings.homeLocation?.address);
      if (coordinates != null) {
        updateData = {
          ...updateData,
          homeLocation: {
            ...userSettings.homeLocation,
            latitude: coordinates[0],
            longitude: coordinates[1]
          }
        };
      }
    }

    const updatedUserSettings = await Api.getUsersApi(accessToken).updateUser({ userId: accessToken.userId, user: updateData });

    this.setState({
      userSettings: updatedUserSettings,
      modified: false
    });
  };

  /**
   * On home address change handler
   *
   * @param event event
   */
  private onHomeAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { userSettings } = this.state;

    if (!userSettings) {
      return;
    }

    const { value, name } = event.target;
    const updatedUserSetting: User = {
      ...userSettings,
      homeLocation: {
        ...userSettings.homeLocation,
        name: userSettings.homeLocation?.name || "",
        latitude: INITIAL_LONDON_CORRDINATES[0],
        longitude: INITIAL_LONDON_CORRDINATES[1],
        address: {
          ...userSettings.homeLocation?.address || INITIAL_HOME_ADDRESS,
          [name]: value
        }
      }
    };
    this.setState({
      userSettings: updatedUserSetting,
      modified: true
    });
  };

  /**
   * On medical condition check handler
   *
   * @param medicalConditionId medical condition id
   * @param event event
   */
  private onMedicalConditionCheck = (medicalConditionId: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { userSettings } = this.state;

    if (!userSettings) {
      return;
    }

    const updatedUserSetting: User = {
      ...userSettings,
      medicalConditions: userSettings.medicalConditions?.filter(conditionId => conditionId !== medicalConditionId) || []
    };

    if (event.target.checked) {
      updatedUserSetting.medicalConditions?.push(medicalConditionId);
    }

    this.setState({
      userSettings: updatedUserSetting,
      modified: true
    });
  };

  /**
   * On transportation check handler
   * 
   * @param routeType route type
   */
  private onTransportationMethodCheck = (routeType: TransportationType) => () => {
    const { userSettings } = this.state;

    if (!userSettings) {
      return;
    }

    const updatedUserSetting: User = {
      ...userSettings,
      transportPreference: routeType
    };

    this.setState({
      userSettings: updatedUserSetting,
      modified: true
    });
  };

  /**
   * On change account settings handler
   */
  private onChangeAccountSettings = () => {
    const { keycloak } = this.props;

    if (!keycloak) {
      return;
    }

    window.open(keycloak.createAccountUrl());
  };

  /**
   * On delete account click handler
   */
  private onDeleteAccount = () => {
    this.setState({
      deletingAccount: true
    });
  };

  /**
   * On delete account cancel click handler
   */
  private onDeleteAccountCancel = () => {
    this.setState({
      deletingAccount: false
    });
  };

  /**
   * On delete account confirm click handler
   */
  private onDeleteAccountConfirm = async () => {
    const { accessToken, onLogout } = this.props;

    if (!accessToken?.userId) {
      return;
    }

    const usersApi = Api.getUsersApi(accessToken);
    await usersApi.deleteUser({ userId: accessToken.userId });
    onLogout();
    window.location.href = "/";
  };

}

/**
 * Redux mapper for mapping store state to component props
 * @param state store state
 */
export function mapStateToProps(state: ReduxState) {
  return {
    accessToken: state.auth.accessToken,
    keycloak: state.auth.keycloak
  };
}

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({
  onLogout: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Settings));