import { DefaultRoutingMode, Pollutant } from "../generated/client";
import { OTPParserParams } from "../types";
import Color from "color";

const maxWalkDistanceQuery = "maxWalkDistance";
const timeQuery = "time";
const dateQuery = "date";
const fromPlaceQuery = "fromPlace";
const toPlaceQuery = "toPlace";

export interface OTPPollutantParameters {
  [parameterName: string] : number
}

/**
 * Utility class for routing
 */
export default class RoutingUtils {

  /**
   * Return a parsed api string with query parameter
   * 
   * @param params parser params
   * @return parsed api query string
   */
  public static OTPApiStringParser = (params: OTPParserParams): string => {
    const {
      defaultRoutingMode,
      pollutants,
      time,
      date,
      maxWalkDistance,
      baseUrl,
      fromPlace,
      toPlace
    } = params;

    if (!baseUrl) {
      throw new Error("Invalid otp url!");
    }

    if (!fromPlace) {
      throw new Error("Invalid from place url!");
    }

    if (!toPlace) {
      throw new Error("Invalid to place url!");
    }

    const queries = RoutingUtils.routingModeQueryParser(defaultRoutingMode, pollutants);

    queries.push(RoutingUtils.constructQuery(maxWalkDistanceQuery, maxWalkDistance.toString()));
    queries.push(RoutingUtils.constructQuery(timeQuery, time));
    queries.push(RoutingUtils.constructQuery(dateQuery, date));
    queries.push(RoutingUtils.constructQuery(fromPlaceQuery, fromPlace.toString()));
    queries.push(RoutingUtils.constructQuery(toPlaceQuery, toPlace.toString()));

    const serializedQueries = RoutingUtils.concatQueries(queries);

    return `${baseUrl}?${serializedQueries}`;
  };

  /**
   * Return a parsed query list constructed from defaultRoutingMode
   * 
   * @param defaultRoutingMode default routing mode
   * @param pollutants pollutant list
   * @return parsed query list
   */
  private static routingModeQueryParser = (defaultRoutingMode: DefaultRoutingMode, pollutants: Pollutant[]): string[] => {
    const { pollutantPenalties } = defaultRoutingMode;

    if (!pollutantPenalties || pollutantPenalties.length === 0 || pollutants.length === 0) {
      return [];
    }

    const pollutantQueriesRaw: OTPPollutantParameters = {};

    pollutants.forEach(pollutant => {
      pollutantQueriesRaw[pollutant.requestParameters.penaltyRequestParameter] = 0;
      pollutantQueriesRaw[pollutant.requestParameters.thresholdRequestParameter] = 0;
    });

    pollutantPenalties.forEach(pollutantPenalty => {
      const pollutantFound = pollutants.find(pollutant => pollutant.id === pollutantPenalty.pollutantId);
      if (!pollutantFound) {
        return;
      }

      (pollutantQueriesRaw[pollutantFound.requestParameters.thresholdRequestParameter] = pollutantPenalty.threshold);
      (pollutantQueriesRaw[pollutantFound.requestParameters.penaltyRequestParameter] = pollutantPenalty.penalty);
    });

    return Object.keys(pollutantQueriesRaw).map(pollutantQueryName => RoutingUtils.constructQuery(pollutantQueryName, pollutantQueriesRaw[pollutantQueryName].toString()));
  };

  /**
   * Return a concatenated query string from the query string list
   * 
   * @param queries query string list
   * @return concatenated query string
   */
  private static concatQueries = (queries: string[]): string => {
    return queries.reduce((prevValue, curValue) => `${prevValue}&${curValue}`);
  };

  /**
   * Return a single query
   * 
   * @param queryName query name
   * @param queryValue query value
   * @return formatted query
   */
  private static constructQuery = (queryName: string, queryValue: string): string => {
    return `${queryName}=${queryValue}`;
  };

  /**
   * Return a color for a pollutant
   * 
   * @param highDensityColor hight density color
   * @param lowDensityColor low density color
   * @param baseColor base color
   * @param fraction fraction value
   * @return pollutant color
   */
  public static getPollutantColorFaction = (highDensityColor: string, lowDensityColor: string, baseColor: string, fraction: number): string => {
    const highColor = Color(highDensityColor);
    const lowColor = Color(lowDensityColor);
    const basedColor = Color(baseColor);
    const baseColorOpacity = 0.8;
    const pollutantColorOpacity = Math.max(0, Math.min(fraction, 1));

    const pollutantColor = lowColor.mix(highColor, pollutantColorOpacity);

    const pollutantColorWithBase = basedColor.mix(pollutantColor, baseColorOpacity);

    return pollutantColorWithBase.hex();
  };

}