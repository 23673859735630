import { Box, CircularProgress, TextField, Button, Typography, MenuItem } from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import Api from "../../../api";
import { DefaultRoutingMode, Pollutant, PollutantPenalty, RoutingModeIcon } from "../../../generated/client/models";
import strings from "../../../localization/strings";
import { ReduxActions, ReduxState } from "../../../store";
import { NullableToken } from "../../../types";
import { styles } from "./management-routing-mode.styles";
import ConfirmDialog from "../../generic/dialogs/confirm-dialog";
import ManagedItemAccordion from "../../generic/manageable-items/managed-accordion";
import AddIcon from "@material-ui/icons/Add";
import theme from "../../../theme/theme";
import RoutingModeIconRenderer from "../../generic/routing-mode-icon/routing-mode-icon-renderer";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  accessToken?: NullableToken;
  keycloak?: Keycloak.KeycloakInstance;
  setError: (error: any) => void;
}

/**
 * Interface describing component state
 */
interface State {
  pollutants?: Pollutant[];
  deletingRoutingMode: boolean;
  deletingRoutingModeId?: string;
  defaultRoutingModes?: DefaultRoutingMode[],
  isLoading: boolean,
  deletingDraft: boolean;
  creatingRoutingMode: boolean;
  deletingDraftRoutingModeId?: string;
  pendingNewRoutingModeName: string;
  onRoutingModeCancelCallback?: () => void;
}

/**
 * Component for management screen routing mode view
 */
class DefaultRoutingModeView extends React.Component<Props, State> {

  /**
   * Component constructor
   * 
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      pendingNewRoutingModeName: "",
      creatingRoutingMode: false,
      deletingRoutingMode: false,
      isLoading: false,
      deletingDraft: false
    };
  }

  /**
   * Component life cycle method
   */
  public componentDidMount = async () => {
    this.loadData();
  };

  /**
   * Component render method
   */
  public render() {
    const { classes } = this.props;
    const { isLoading, defaultRoutingModes } = this.state;

    if (isLoading) {
      return (
        <Box className={ classes.loadingContainer }>
          <CircularProgress size={ 64 }/>
        </Box>
      );
    }

    if (!defaultRoutingModes) {
      return null;
    }

    return (
      <>
        <Box className={ classes.scrollableRoutingModesContainer }>
          { defaultRoutingModes.map(this.renderDefaultRoutingMode) }
          { this.renderNewRoutingMode() }
        </Box>
        { this.renderCancelRoutingModeDialog() }
        { this.renderCreateRoutingModeDialog() }
        { this.renderDeleteRoutingModeDialog() }
        
      </>
    );
  }

  /**
   * Renders default routing mode generals
   * 
   * @param defaultRoutingMode default routing mode data
   * @param callback callback
   */
  public managedItemGeneralsRenderer = (defaultRoutingMode: DefaultRoutingMode) => (callback: () => void) => {
    const { classes } = this.props;

    return (
      <Box className={ classes.generalsContainer }>
        { this.renderEditRoutingModeName(defaultRoutingMode, callback) }
        <TextField
          select
          size="small"
          label={ `${strings.management.defaultRoutingModes.icon}: ` }
          value={ defaultRoutingMode.icon }
          onChange={ this.onRoutingModeIconSelect(defaultRoutingMode, callback) }
        >
          { Object.values(RoutingModeIcon).map(
            iconName => (
              <MenuItem value={ iconName }>
                <RoutingModeIconRenderer iconName={ iconName }/>
              </MenuItem>
            )
          )
          }
        </TextField>
      </Box>
    );
  };

  /**
   * Renders edit routing mode name
   *    
   * @param defaultRoutingMode default routing mode data
   * @param callback callback
   */
  private renderEditRoutingModeName = (defaultRoutingMode: DefaultRoutingMode, callback: () => void) => {
    const { classes } = this.props;

    return (
      <Box className={ classes.editName }>
        <Box width={ 200 }>
          <Typography
            variant="h3"
            style={{
              fontWeight: 600
            }}
          >
            { `${strings.management.defaultRoutingModes.routingModeName}: ` }
          </Typography>
        </Box>
        <Box className={ classes.editNameTextField }>
          <TextField
            variant="standard"
            value={ defaultRoutingMode.name }
            onChange={ this.onRoutingModeNameEditing(defaultRoutingMode, callback) }
            className={ classes.routingModeNameInput }
          />
        </Box>
      </Box>
    );
  };

  /**
   * Renders default routing mode card
   * 
   * @param customDefaultRoutingMode custom default routing mode object
   */
  public renderDefaultRoutingMode = (defaultRoutingMode: DefaultRoutingMode) => {
    const { pollutants } = this.state;

    if (!defaultRoutingMode.id || !pollutants) {
      return;
    }

    return (
      <ManagedItemAccordion
        managedItem={ defaultRoutingMode }
        managedItemTitle={ defaultRoutingMode.name }
        managedItemPenalties={ defaultRoutingMode.pollutantPenalties || [] }
        pollutants={ pollutants }
        onManagedItemCancel={ this.onRoutingModeCancel(defaultRoutingMode) }
        onManagedItemSave={ this.onRoutingModeSave(defaultRoutingMode) }
        onManagedItemDelete={ this.onRoutingModeDelete(defaultRoutingMode) }
        onManagedItemPenaltiesUpdate={ this.onRoutingModePenaltiesUpdate(defaultRoutingMode) }
        managedItemGeneralsRenderer={ this.managedItemGeneralsRenderer(defaultRoutingMode) }
      />
    );
  };

  /**
   * Renders new routing mode button
   */
  private renderNewRoutingMode = () => {
    const { classes } = this.props;

    return (
      <Button
        onClick={ this.onCreateNewRoutingModeClick }
        className={ classes.newRoutingMode }
      >
        <AddIcon style={{ color: theme.palette.primary.light }}/>
        <Box ml={ 1 }>
          <Typography className={ classes.newRoutingModeText }>
            { strings.management.defaultRoutingModes.newRoutingMode }
          </Typography>
        </Box>
      </Button>
    );
  };

  /**
   * Renders cancel routing mode dialog
   */
  private renderCancelRoutingModeDialog = () => {
    const { deletingDraft } = this.state;

    return (
      <ConfirmDialog
        title={ strings.management.defaultRoutingModes.dialogs.deleteDraft.title }
        text={ strings.management.defaultRoutingModes.dialogs.deleteDraft.text }
        positiveButtonText={ strings.common.confirm }
        cancelButtonText={ strings.common.cancel }
        dialogVisible={ deletingDraft }
        onDialogConfirm={ this.onDeleteDraftConfirm }
        onDialogCancel={ this.onDeleteDraftCancel }
      />
    );
  };

  /**
   * Renders create routing mode dialog
   */
  private renderCreateRoutingModeDialog = () => {
    const { creatingRoutingMode } = this.state;

    return (
      <ConfirmDialog
        title={ strings.management.defaultRoutingModes.dialogs.createNewRoutingMode.title }
        text={ strings.management.defaultRoutingModes.dialogs.createNewRoutingMode.text }
        positiveButtonText={ strings.common.confirm }
        cancelButtonText={ strings.common.cancel }
        dialogVisible={ creatingRoutingMode }
        onDialogConfirm={ this.onCreateRoutingModeConfirm }
        onDialogCancel={ this.onCreateRoutingModeCancel }
        userInput={ this.renderDialogNameInput }
      />
    );
  };

  /**
   * Renders delete routing mode dialog
   */
  private renderDeleteRoutingModeDialog = () => {
    const { deletingRoutingMode } = this.state;

    return (
      <ConfirmDialog
        title={ strings.management.defaultRoutingModes.dialogs.deleteRoutingMode.title }
        text={ strings.management.defaultRoutingModes.dialogs.deleteRoutingMode.text }
        positiveButtonText={ strings.common.confirm }
        cancelButtonText={ strings.common.cancel }
        dialogVisible={ deletingRoutingMode }
        onDialogConfirm={ this.onDeleteRoutingModeConfirm }
        onDialogCancel={ this.onDeleteRoutingModeCancel }
      />
    );
  };

  /**
   * Renders user input field for dialog
   */
  private renderDialogNameInput = () => {
    const { pendingNewRoutingModeName } = this.state;

    return (
      <TextField
        label={ strings.management.defaultRoutingModes.dialogs.createNewRoutingMode.newRoutingModeName }
        value={ pendingNewRoutingModeName }
        onChange={ this.onPendingNewRoutingModeNameChange }
      />
    );
  };

  /**
   * On routing mode name edit event handler
   * 
   * @param defaultRoutingMode default routing mode data 
   * @param callback callback
   */
  private onRoutingModeNameEditing = (defaultRoutingMode: DefaultRoutingMode, callback: () => void) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedRoutingMode: DefaultRoutingMode = {
      ...defaultRoutingMode,
      name: event.target.value as string
    };

    this.onRoutingModeUpdate(updatedRoutingMode, callback);
  };

  /**
   * Confirm create new routing mode handler
   */
  private onCreateRoutingModeConfirm = async () => {
    const { accessToken, setError } = this.props;
    const { pendingNewRoutingModeName, defaultRoutingModes } = this.state;

    if (!accessToken || !defaultRoutingModes) {
      return;
    }

    const newRoutingMode: DefaultRoutingMode = {
      name: pendingNewRoutingModeName,
      icon: RoutingModeIcon.Heart
    };

    try {
      Api.getDefaultRoutingModeApi(accessToken).createRoutingMode({
        defaultRoutingMode: newRoutingMode
      }).then(createdRoutingMode => {
        if (!createdRoutingMode.id) {
          return;
        }

        this.setState({
          defaultRoutingModes: [ ...defaultRoutingModes, createdRoutingMode ],
          creatingRoutingMode: false,
          pendingNewRoutingModeName: ""
        });
      });
    } catch (error) {
      setError(error);
    }
  };

  /**
   * Cancel create new routing mode handler
   */
  private onCreateRoutingModeCancel = () => {
    this.setState({
      creatingRoutingMode: false,
      pendingNewRoutingModeName: ""
    });
  };

  /**
   * Confirm delete routing mode handler
   */
  private onDeleteRoutingModeConfirm = async () => {
    const { deletingRoutingModeId } = this.state;
    const { accessToken, setError } = this.props;

    if (!deletingRoutingModeId) {
      return;
    }

    this.setState({
      deletingRoutingMode: false
    });

    try {
      Api.getDefaultRoutingModeApi(accessToken).deleteRoutingMode({
        routingModeId: deletingRoutingModeId
      }).then(() => {
        this.setState({
          deletingRoutingModeId: undefined
        });

        this.loadData();
      });
    } catch (error) {
      setError(error);
    }
  };
  
  /**
   * Cancel delete routing mode handler
   */
  private onDeleteRoutingModeCancel = () => {
    this.setState({
      deletingRoutingMode: false,
      deletingRoutingModeId: undefined
    });
  };

  /**
   * Load default routing mode data
   */
  private loadData = async () => {
    const { accessToken, setError } = this.props;

    this.setState({
      isLoading: true
    });

    try {
      await Api.getPollutantsApi(accessToken).getPollutants({})
        .then(pollutants =>
          this.setState({
            pollutants: pollutants
          }));

      await Api.getDefaultRoutingModeApi(accessToken).getRoutingModes({})
        .then(defaultRoutingModes =>
          this.setState({
            defaultRoutingModes: defaultRoutingModes
          }));
    } catch (error) {
      setError(error);
    }

    this.setState({
      isLoading: false
    });
  };

  /**
   * Routing mode icon select event handler
   * 
   * @param defaultRoutingMode default routing mode data
   * @param callback callback
   */
  private onRoutingModeIconSelect = (defaultRoutingMode: DefaultRoutingMode, callback: () => void) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedDefaultRoutingMode: DefaultRoutingMode = {
      ...defaultRoutingMode,
      icon: event.target.value as RoutingModeIcon
    };

    this.onRoutingModeUpdate(updatedDefaultRoutingMode, callback);
  };

  /**
   * Routing mode save click handler
   * 
   * @param defaultRoutingMode default routing mode data
   * @param callback callback
   */
  private onRoutingModeSave = (defaultRoutingMode: DefaultRoutingMode) => (callback: () => void) => {
    const { accessToken, setError } = this.props;
    const { defaultRoutingModes } = this.state;

    if (!defaultRoutingMode.id || !defaultRoutingModes) {
      return;
    }

    const preProcessedDefaultRoutingMode: DefaultRoutingMode = {
      ...defaultRoutingMode,
      pollutantPenalties: defaultRoutingMode.pollutantPenalties ? defaultRoutingMode.pollutantPenalties
        .map(penalty => ({
          ...penalty,
          id: undefined
        })) : []
    };

    try {
      Api.getDefaultRoutingModeApi(accessToken).updateRoutingMode({
        defaultRoutingMode: preProcessedDefaultRoutingMode,
        routingModeId: defaultRoutingMode.id
      }).then(newRoutingMode =>
        this.onRoutingModeUpdate(newRoutingMode, callback));
    } catch (error) {
      setError(error);
    }
  };

  /**
   * Deleting draft handler
   * 
   * @param defaultRoutingMode default routing mode data
   * @param callback callback
   */
  private onRoutingModeCancel = (defaultRoutingMode: DefaultRoutingMode) => (callback: () => void) => {
    if (!defaultRoutingMode.id) {
      return;
    }

    this.setState({
      deletingDraft: true,
      deletingDraftRoutingModeId: defaultRoutingMode.id,
      onRoutingModeCancelCallback: callback
    });
  };

  /**
   * Confirm deleting draft handler
   */
  private onDeleteDraftConfirm = async () => {
    const { accessToken, setError } = this.props;
    const { deletingDraftRoutingModeId, defaultRoutingModes, onRoutingModeCancelCallback } = this.state;

    if (!deletingDraftRoutingModeId || !defaultRoutingModes) {
      return;
    }

    try {
      await Api.getDefaultRoutingModeApi(accessToken).findRoutingMode({
        routingModeId: deletingDraftRoutingModeId
      }).then(originalDefaultRoutingMode => {
        this.onRoutingModeUpdate(originalDefaultRoutingMode, onRoutingModeCancelCallback);

        this.setState({
          deletingDraft: false,
          deletingDraftRoutingModeId: undefined,
          onRoutingModeCancelCallback: undefined
        });
      });
    } catch (error) {
      setError(error);
    }
  };

  /**
   * Deleting routing mode handler
   * 
   * @param defaultRoutingMode default routing mode data
   */
  private onRoutingModeDelete = (defaultRoutingMode: DefaultRoutingMode) => () => {
    if (!defaultRoutingMode.id) {
      return;
    }

    this.setState({
      deletingRoutingMode: true,
      deletingRoutingModeId: defaultRoutingMode.id
    });
  };

  /**
   * Deleting routing mode handler
   * 
   * @param defaultRoutingMode default routing mode data
   * @param callback callback
   */
  private onRoutingModeUpdate = (updatedRoutingMode: DefaultRoutingMode, callback?: () => void) => {
    const { defaultRoutingModes } = this.state;

    if (!updatedRoutingMode.id || !defaultRoutingModes) {
      return;
    }

    const updatedDefaultRoutingModes = defaultRoutingModes.map(routingModeData => (routingModeData.id === updatedRoutingMode.id ? updatedRoutingMode : routingModeData));

    this.setState({
      defaultRoutingModes: updatedDefaultRoutingModes
    });

    callback && callback();
  };

  /**
   * Routing mode penalty update handler
   * 
   * @param defaultRoutingMode default routing mode data
   * @param managedItemPenalties managed item penalties
   * @param callback callback
   */
  private onRoutingModePenaltiesUpdate = (defaultRoutingMode: DefaultRoutingMode) => (managedItemPenalties: PollutantPenalty[], callback: () => void) => {
    const { defaultRoutingModes } = this.state;

    if (!defaultRoutingMode.id || !defaultRoutingModes) {
      return;
    }

    const updatedDefaultRoutingMode: DefaultRoutingMode = {
      ...defaultRoutingMode,
      pollutantPenalties: managedItemPenalties
    };

    this.onRoutingModeUpdate(updatedDefaultRoutingMode, callback);
  };

  /**
   * Cancel deleting draft handler
   */
  private onDeleteDraftCancel = () => {
    this.setState({
      deletingDraft: false,
      deletingDraftRoutingModeId: undefined
    });
  };

  /**
   * New routing mode name change handler
   * 
   * @param event input change event
   */
  private onPendingNewRoutingModeNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      pendingNewRoutingModeName: event.target.value as string
    });
  };

  /**
     * Create new routing mode click handler
     */
  private onCreateNewRoutingModeClick = () => {
    this.setState({
      creatingRoutingMode: true
    });
  };

}

/**
 * Redux mapper for mapping store state to component props
 * 
 * @param state store state
 */
export function mapStateToProps(state: ReduxState) {
  return {
    accessToken: state.auth.accessToken,
    keycloak: state.auth.keycloak
  };
}

/**
 * Redux mapper for mapping component dispatches 
 * 
 * @param dispatch dispatch method
 */
export function mapDispatchToProps(dispatch: Dispatch<ReduxActions>) {
  return {
  };
}

const Styled = withStyles(styles)((DefaultRoutingModeView));

export default connect(mapStateToProps, mapDispatchToProps)(Styled);