/* tslint:disable */
/* eslint-disable */
/**
 * Megasense data portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Parameter that is used for OTP routing for the given pollutant
 * @export
 * @interface PollutantRequestParameter
 */
export interface PollutantRequestParameter {
    /**
     * 
     * @type {string}
     * @memberof PollutantRequestParameter
     */
    penaltyRequestParameter: string;
    /**
     * 
     * @type {string}
     * @memberof PollutantRequestParameter
     */
    thresholdRequestParameter: string;
}

export function PollutantRequestParameterFromJSON(json: any): PollutantRequestParameter {
    return PollutantRequestParameterFromJSONTyped(json, false);
}

export function PollutantRequestParameterFromJSONTyped(json: any, ignoreDiscriminator: boolean): PollutantRequestParameter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'penaltyRequestParameter': json['penaltyRequestParameter'],
        'thresholdRequestParameter': json['thresholdRequestParameter'],
    };
}

export function PollutantRequestParameterToJSON(value?: PollutantRequestParameter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'penaltyRequestParameter': value.penaltyRequestParameter,
        'thresholdRequestParameter': value.thresholdRequestParameter,
    };
}


