import { withStyles, WithStyles } from "@material-ui/core/styles";
import React from "react";
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, ReferenceLine, Label } from "recharts";
import { Pollutant } from "../../generated/client";
import { ExposureStatisticsData, StatisticScope } from "../../types";
import { styles } from "./exposure-bar-chart.styles";

interface Props extends WithStyles<typeof styles> {
  displayedExposureInstances: ExposureStatisticsData[];
  statisticScope: StatisticScope;
  pollutant: Pollutant;
}

interface State {
}

/**
 * ExposureBarChart component
 */
class ExposureBarChart extends React.Component<Props, State> {

  /**
   * Component constructor
   * 
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
    };
  }

  /**
   * Component render
   */
  public render = () => {
    const { displayedExposureInstances, pollutant } = this.props;

    const pollutantLimit = pollutant.limit || 0;
    const pollutantRecommendation = pollutant.recommendation || 0;
    const maxEntryValue = Math.ceil(Math.max(...displayedExposureInstances.map(displayedExposureInstance => displayedExposureInstance.exposureAmount)));
    const chartDomain = Math.max(pollutantLimit, maxEntryValue);
    const gradientRange = (pollutantLimit - pollutantRecommendation) / chartDomain;
    const gradientStart = pollutantRecommendation / chartDomain;

    return (
      <ResponsiveContainer>
        <BarChart
          data={ displayedExposureInstances }
          margin={{
            top: 32, left: 32, right: 32, bottom: 32
          }}
          barCategoryGap="8"
        >
          <defs>
            <linearGradient
              id="colorUv"
              x1="0"
              y1="0"
              x2="0"
              y2="100%"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={ 1 - (gradientStart + gradientRange) } stopColor="#ff3964"/>
              <stop offset={ 1 - (gradientStart + (gradientRange * 0.75)) } stopColor="#C28688"/>
              <stop offset={ 1 - (gradientStart + (gradientRange * 0.5)) } stopColor="#B29DA3"/>
              <stop offset={ 1 - (gradientStart + (gradientRange * 0.25)) } stopColor="#A2B1BB"/>
              <stop offset={ 1 - gradientStart } stopColor="#91C4D1"/>
            </linearGradient>
          </defs>
          <CartesianGrid vertical={ false } strokeDasharray="3 3"/>
          <XAxis dataKey="name" axisLine={ false } stroke="#FFF"/>
          <YAxis axisLine={ false } stroke="#FFF" unit={ pollutant.unit } domain={ [0, chartDomain] }/>
          <Tooltip cursor={ false }/>
          <Bar dataKey="exposureAmount" stackId="a" fill="url(#colorUv)"/>
          <ReferenceLine
            isFront
            stroke="red"
            y={ pollutantRecommendation }
          >
            <Label value={ `${pollutantRecommendation}${pollutant.unit} `} fill="#fff"/>
          </ReferenceLine>
        </BarChart>
      </ResponsiveContainer>
    );
  };

}

export default withStyles(styles)(ExposureBarChart);