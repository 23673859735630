import React from "react";
import { Box, Accordion, AccordionSummary, AccordionDetails, Tooltip, Typography, withStyles, WithStyles, Button, IconButton, TextField, MenuItem } from "@material-ui/core";
import { DataGrid, GridColDef, GridRowId, GridEditRowsModel, GridCellParams } from "@material-ui/data-grid";
import strings from "../../../localization/strings";
import { styles } from "./managed-accordion.styles";
import { Pollutant, PollutantPenalty } from "../../../generated/client";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import theme from "../../../theme/theme";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { v4 as uuidv4 } from "uuid";
import { ManagedItem } from "../../../types";

/**
 * Interface describing component props
 */
interface Props<T extends ManagedItem> extends WithStyles<typeof styles> {
  managedItem: T;
  managedItemTitle: string;
  managedItemPenalties: PollutantPenalty[];
  pollutants: Pollutant[];
  onManagedItemCancel: (callback: () => void) => void;
  onManagedItemSave: (callback: () => void) => void;
  onManagedItemDelete: () => void;
  onManagedItemPenaltiesUpdate: (managedItemPenalties: PollutantPenalty[], callback: () => void) => void;
  managedItemGeneralsRenderer: (updateCallback: () => void) => void;
}

/**
 * Interface describing component state
 */
interface State {
  modified: boolean;
  pendingPollutantId?: string;
  dataGridRowSelected: boolean;
  selectedDataRowIds: GridRowId[];
}

/**
 * Component for managed item accordion
 */
class ManagedItemAccordion<T extends ManagedItem> extends React.Component<Props<T>, State> {

  /**
   * Component constructor
   *
   * @param props props
   */
  constructor(props: Props<T>) {
    super(props);
    this.state = {
      modified: false,
      dataGridRowSelected: false,
      selectedDataRowIds: []
    };
  }

  /**
   * Component render method
   */
  public render() {
    const {
      classes,
      managedItem,
      managedItemTitle,
      onManagedItemCancel,
      onManagedItemSave
    } = this.props;
    const { modified } = this.state;

    if (!managedItem.id) {
      return;
    }

    return (
      <Box
        id={ managedItem.id }
        className={ classes.managedItemContainer }
      >
        <Accordion defaultExpanded={ false } >
          <AccordionSummary
            expandIcon={ <ExpandMoreIcon htmlColor={ theme.palette.primary.light }/> }
            className={ classes.managedItemTitle }
          >
            <Typography
              variant="h2"
              className={ classes.managedItemTitleText }
            >
              { managedItemTitle }
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box className={ classes.managedItemDetail }>
              { this.renderManagedItemGenerals() }
              { this.renderPollutantPenalties() }
              { this.renderAddNewPollutantPenalty() }
              <Box className={ classes.buttonContainer }>
                <Button
                  disableElevation
                  variant="contained"
                  disabled={ !modified }
                  onClick={ () => onManagedItemCancel(this.setNotModified) }
                  className={ classes.deleteDraftButton }
                >
                  { strings.common.cancel }
                </Button>
                <Button
                  disableElevation
                  variant="contained"
                  disabled={ !modified }
                  onClick={ () => onManagedItemSave(this.setNotModified) }
                  className={ classes.saveButton }
                >
                  { strings.common.save }
                </Button>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  }
  
  /**
   * Renders general managed items
   */
  private renderManagedItemGenerals = () => {
    const {
      classes,
      pollutants,
      managedItem,
      onManagedItemDelete,
      managedItemGeneralsRenderer
    } = this.props;

    if (!managedItem.id || !pollutants) {
      return;
    }

    return (
      <Box className={ classes.managedItemGeneralsContainer }>
        { managedItemGeneralsRenderer(this.setModified) }
        <Tooltip title={ strings.managedItemAccordion.deleteItem }>
          <IconButton
            component="span"
            className={ classes.deleteManagedItemButton }
            onClick={ () => onManagedItemDelete() }
          >
            <DeleteOutlineIcon htmlColor={ theme.palette.error.main }/>
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  /**
   * Renders pollutant penalties
   */
  private renderPollutantPenalties = () => {
    const {
      classes,
      pollutants,
      managedItem,
      managedItemPenalties
    } = this.props;
    const {
      dataGridRowSelected
    } = this.state;

    if (!pollutants || !managedItem.id) {
      return;
    }

    const columns: GridColDef[] = [
      {
        field: "pollutantName",
        headerName: strings.managedItemAccordion.pollutantName,
        type: "string",
        width: 250,
        editable: false,
        resizable: true,
        renderCell: (params: GridCellParams) => (
          <Tooltip title={ params.formattedValue?.toString() || params.id }>
            <Typography>
              { params.formattedValue }
            </Typography>
          </Tooltip>
        )
      },
      {
        field: "penalty",
        headerName: strings.managedItemAccordion.pollutantPenalty,
        type: "number",
        width: 200,
        editable: true,
        resizable: true
      },
      {
        field: "threshold",
        headerName: strings.managedItemAccordion.pollutantThreshold,
        type: "number",
        width: 200,
        editable: true,
        resizable: true
      }
    ];

    const rows = managedItemPenalties.map(penalty => ({
      id: penalty.id,
      pollutantName: pollutants.find(pollutant => pollutant.id === penalty.pollutantId)?.displayName || penalty.id,
      penalty: penalty.penalty,
      threshold: penalty.threshold
    }));

    return (
      <>
        <Box className={ classes.dataGridContainer }>
          <DataGrid
            checkboxSelection
            disableSelectionOnClick
            rows={ rows }
            columns={ columns }
            pageSize={ 4 }
            // onEditRowsModelChange={ this.onEditRowModelChange }
            onSelectionModelChange={ this.onSelectionModelChange }
          />
          { dataGridRowSelected &&
            <Box className={ classes.deletePollutantButtonContainer }>
              <Button
                disableElevation
                variant="contained"
                disabled={ !dataGridRowSelected }
                className={ classes.deletePollutantButton }
                onClick={ this.onDataGridRowRemove }
              >
                { strings.common.remove }
              </Button>
            </Box>
          }
        </Box>
      </>
    );
  };

  /**
   * Renders new pollutant penalty
   */
  private renderAddNewPollutantPenalty = () => {
    const {
      classes,
      pollutants
    } = this.props;
    const { pendingPollutantId } = this.state;
    const selected = !!pendingPollutantId;

    if (!pollutants) {
      return;
    }

    return (
      <Box className={ classes.newPenaltyContainer }>
        <Typography>
          { `${strings.managedItemAccordion.newPenalty}: ` }
        </Typography>
        <Box display="flex" alignItems="center">
          <TextField
            select
            size="small"
            variant="outlined"
            label={ `${strings.managedItemAccordion.newPenaltyPollutantName}: ` }
            value={ pendingPollutantId }
            className={ classes.newPollutantSelect }
            onChange={ this.onNewPenaltySelect }
          >
            { this.renderNewPollutantOptions() }
          </TextField>
          <IconButton
            disabled={ !selected }
            aria-label="add new penalty"
            component="span"
            className={ classes.addPenaltyButton }
            onClick={ () => this.onNewPollutantPenaltyAdd() }
          >
            <AddCircleOutlineIcon htmlColor={ selected ? theme.palette.primary.main : "rgba(0, 0, 0, 0.5)"}/>
          </IconButton>
        </Box>
      </Box>
    );
  };

  /**
   * Renders new pollutant options
   */
  private renderNewPollutantOptions = () => {
    const { pollutants, managedItemPenalties } = this.props;

    if (!pollutants || !managedItemPenalties) {
      return;
    }

    return pollutants.map(
      pollutant => !(managedItemPenalties
        .find(penalty =>
          penalty.pollutantId === pollutant.id)
      ) && (
        <MenuItem value={ pollutant.id }>{ pollutant.displayName }</MenuItem>
      )
    );
  };

  /**
   * Select new penalty handler
   * 
   * @param event New penalty selection params
   */
  private onNewPenaltySelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({
      pendingPollutantId: event.target.value as string
    });
  };

  /**
   * Select row model handler
   * 
   * @param selectionModel selection row Id array
   */
  private onSelectionModelChange = (selectionModel: GridRowId[]) => {
    if (!selectionModel.length) {
      this.setState({
        dataGridRowSelected: false,
        selectedDataRowIds: []
      });
      return;
    }

    this.setState({
      dataGridRowSelected: true,
      selectedDataRowIds: selectionModel
    });
  };

  /**
   * Edit row model handler
   * 
   * @param params Grid edit row model params
   */
  private onEditRowModelChange = (params: GridEditRowsModel) => {
    const {
      managedItem,
      managedItemPenalties,
      onManagedItemPenaltiesUpdate
    } = this.props;

    const selectedPenaltyId = Object.keys(params)[0];

    if (!selectedPenaltyId || !managedItemPenalties || !managedItem.id) {
      return;
    }

    const columnName = Object.keys(params[selectedPenaltyId])[0];
    const editedValue = params[selectedPenaltyId][columnName].value;
    const selectedPollutantPenalty = managedItemPenalties.find(penalty => penalty.id === selectedPenaltyId)!;

    const updatedManagedItemPenalties = managedItemPenalties.map(
      pollutantPenalty => (pollutantPenalty.id === selectedPenaltyId ?
        {
          ...selectedPollutantPenalty,
          [columnName]: editedValue
        } : pollutantPenalty)
    );

    onManagedItemPenaltiesUpdate(updatedManagedItemPenalties, this.setModified);
  };

  /**
   * Remove row handler
   */
  private onDataGridRowRemove = () => {
    const { managedItemPenalties, onManagedItemPenaltiesUpdate } = this.props;
    const { selectedDataRowIds } = this.state;

    const updatedManagedItemPenalties = managedItemPenalties.filter(
      pollutantPenalty => (
        !selectedDataRowIds.find(selectedDataRowId => (
          selectedDataRowId === pollutantPenalty.id
        ))
      )
    );

    onManagedItemPenaltiesUpdate(updatedManagedItemPenalties, this.setModified);
  };

  /**
   * Add new pollutantPenalty handler
   */
  private onNewPollutantPenaltyAdd = () => {
    const { managedItemPenalties, onManagedItemPenaltiesUpdate } = this.props;
    const { pendingPollutantId } = this.state;

    if (!managedItemPenalties || !pendingPollutantId) {
      return;
    }

    const tempPollutantPenalty: PollutantPenalty = {
      id: `temp-${uuidv4()}`,
      pollutantId: pendingPollutantId,
      penalty: 0,
      threshold: 0
    };
    const updatedManagedItemPenalties = [ ...managedItemPenalties, tempPollutantPenalty ];

    onManagedItemPenaltiesUpdate(updatedManagedItemPenalties, this.setModified);
  };

  /**
   * Set modified
   */
  private setModified = () => {
    this.setState({
      modified: true
    });
  };

  /**
   * Set not modified
   */
  private setNotModified = () => {
    this.setState({
      modified: false
    });
  };

}

export default withStyles(styles)(ManagedItemAccordion);