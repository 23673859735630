/* tslint:disable */
/* eslint-disable */
/**
 * Megasense data portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MedicalCondition,
    MedicalConditionFromJSON,
    MedicalConditionToJSON,
} from '../models';

export interface CreateMedicalConditionRequest {
    medicalCondition: MedicalCondition;
}

export interface DeleteMedicalConditionRequest {
    medicalConditionId: string;
}

export interface FindMedicalConditionRequest {
    medicalConditionId: string;
}

export interface UpdateMedicalConditionRequest {
    medicalCondition: MedicalCondition;
    medicalConditionId: string;
}

/**
 * 
 */
export class MedicalConditionsApi extends runtime.BaseAPI {

    /**
     * Creates a new medical condition
     */
    async createMedicalConditionRaw(requestParameters: CreateMedicalConditionRequest): Promise<runtime.ApiResponse<MedicalCondition>> {
        if (requestParameters.medicalCondition === null || requestParameters.medicalCondition === undefined) {
            throw new runtime.RequiredError('medicalCondition','Required parameter requestParameters.medicalCondition was null or undefined when calling createMedicalCondition.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/medicalConditions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MedicalConditionToJSON(requestParameters.medicalCondition),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicalConditionFromJSON(jsonValue));
    }

    /**
     * Creates a new medical condition
     */
    async createMedicalCondition(requestParameters: CreateMedicalConditionRequest): Promise<MedicalCondition> {
        const response = await this.createMedicalConditionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes a medical condition
     */
    async deleteMedicalConditionRaw(requestParameters: DeleteMedicalConditionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.medicalConditionId === null || requestParameters.medicalConditionId === undefined) {
            throw new runtime.RequiredError('medicalConditionId','Required parameter requestParameters.medicalConditionId was null or undefined when calling deleteMedicalCondition.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/medicalConditions/{medicalConditionId}`.replace(`{${"medicalConditionId"}}`, encodeURIComponent(String(requestParameters.medicalConditionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a medical condition
     */
    async deleteMedicalCondition(requestParameters: DeleteMedicalConditionRequest): Promise<void> {
        await this.deleteMedicalConditionRaw(requestParameters);
    }

    /**
     * Finds a medical condition
     */
    async findMedicalConditionRaw(requestParameters: FindMedicalConditionRequest): Promise<runtime.ApiResponse<MedicalCondition>> {
        if (requestParameters.medicalConditionId === null || requestParameters.medicalConditionId === undefined) {
            throw new runtime.RequiredError('medicalConditionId','Required parameter requestParameters.medicalConditionId was null or undefined when calling findMedicalCondition.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/medicalConditions/{medicalConditionId}`.replace(`{${"medicalConditionId"}}`, encodeURIComponent(String(requestParameters.medicalConditionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicalConditionFromJSON(jsonValue));
    }

    /**
     * Finds a medical condition
     */
    async findMedicalCondition(requestParameters: FindMedicalConditionRequest): Promise<MedicalCondition> {
        const response = await this.findMedicalConditionRaw(requestParameters);
        return await response.value();
    }

    /**
     * gets all the medical conditions
     */
    async getMedicalConditionsRaw(): Promise<runtime.ApiResponse<Array<MedicalCondition>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/medicalConditions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MedicalConditionFromJSON));
    }

    /**
     * gets all the medical conditions
     */
    async getMedicalConditions(): Promise<Array<MedicalCondition>> {
        const response = await this.getMedicalConditionsRaw();
        return await response.value();
    }

    /**
     * Updates medical condition
     */
    async updateMedicalConditionRaw(requestParameters: UpdateMedicalConditionRequest): Promise<runtime.ApiResponse<MedicalCondition>> {
        if (requestParameters.medicalCondition === null || requestParameters.medicalCondition === undefined) {
            throw new runtime.RequiredError('medicalCondition','Required parameter requestParameters.medicalCondition was null or undefined when calling updateMedicalCondition.');
        }

        if (requestParameters.medicalConditionId === null || requestParameters.medicalConditionId === undefined) {
            throw new runtime.RequiredError('medicalConditionId','Required parameter requestParameters.medicalConditionId was null or undefined when calling updateMedicalCondition.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/medicalConditions/{medicalConditionId}`.replace(`{${"medicalConditionId"}}`, encodeURIComponent(String(requestParameters.medicalConditionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MedicalConditionToJSON(requestParameters.medicalCondition),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicalConditionFromJSON(jsonValue));
    }

    /**
     * Updates medical condition
     */
    async updateMedicalCondition(requestParameters: UpdateMedicalConditionRequest): Promise<MedicalCondition> {
        const response = await this.updateMedicalConditionRaw(requestParameters);
        return await response.value();
    }

}
