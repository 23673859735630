/* tslint:disable */
/* eslint-disable */
/**
 * Megasense data portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AirQuality,
    AirQualityFromJSON,
    AirQualityToJSON,
    RoutePointsAirQuality,
    RoutePointsAirQualityFromJSON,
    RoutePointsAirQualityToJSON,
} from '../models';

export interface GetAirQualityRequest {
    pollutantId?: string;
    boundingBoxCorner1?: string;
    boundingBoxCorner2?: string;
    routingTime?: Date;
}

export interface GetAirQualityForCoordinatesRequest {
    coordinates: string;
    pollutantId: string;
    routingTime?: Date;
}

export interface GetAirQualityForCoordinatesArrayRequest {
    requestBody: Array<string>;
    pollutantId?: string;
    routingTime?: Date;
    routingEnd?: Date;
}

/**
 * 
 */
export class AirQualityApi extends runtime.BaseAPI {

    /**
     * Gets air quality for the area in the bounding box from airquality data file
     * Get air quality data
     */
    async getAirQualityRaw(requestParameters: GetAirQualityRequest): Promise<runtime.ApiResponse<Array<AirQuality>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pollutantId !== undefined) {
            queryParameters['pollutantId'] = requestParameters.pollutantId;
        }

        if (requestParameters.boundingBoxCorner1 !== undefined) {
            queryParameters['boundingBoxCorner1'] = requestParameters.boundingBoxCorner1;
        }

        if (requestParameters.boundingBoxCorner2 !== undefined) {
            queryParameters['boundingBoxCorner2'] = requestParameters.boundingBoxCorner2;
        }

        if (requestParameters.routingTime !== undefined) {
            queryParameters['routingTime'] = (requestParameters.routingTime as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/airQuality`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AirQualityFromJSON));
    }

    /**
     * Gets air quality for the area in the bounding box from airquality data file
     * Get air quality data
     */
    async getAirQuality(requestParameters: GetAirQualityRequest): Promise<Array<AirQuality>> {
        const response = await this.getAirQualityRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get air quality for specific coordinates from airquality data file
     */
    async getAirQualityForCoordinatesRaw(requestParameters: GetAirQualityForCoordinatesRequest): Promise<runtime.ApiResponse<AirQuality>> {
        if (requestParameters.coordinates === null || requestParameters.coordinates === undefined) {
            throw new runtime.RequiredError('coordinates','Required parameter requestParameters.coordinates was null or undefined when calling getAirQualityForCoordinates.');
        }

        if (requestParameters.pollutantId === null || requestParameters.pollutantId === undefined) {
            throw new runtime.RequiredError('pollutantId','Required parameter requestParameters.pollutantId was null or undefined when calling getAirQualityForCoordinates.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.routingTime !== undefined) {
            queryParameters['routingTime'] = (requestParameters.routingTime as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/airQuality/{coordinates}/{pollutantId}`.replace(`{${"coordinates"}}`, encodeURIComponent(String(requestParameters.coordinates))).replace(`{${"pollutantId"}}`, encodeURIComponent(String(requestParameters.pollutantId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AirQualityFromJSON(jsonValue));
    }

    /**
     * Get air quality for specific coordinates from airquality data file
     */
    async getAirQualityForCoordinates(requestParameters: GetAirQualityForCoordinatesRequest): Promise<AirQuality> {
        const response = await this.getAirQualityForCoordinatesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets air quality for array of coordinates (passed as request body) from airquality data file and optionally user\'s calculated exposure.
     */
    async getAirQualityForCoordinatesArrayRaw(requestParameters: GetAirQualityForCoordinatesArrayRequest): Promise<runtime.ApiResponse<RoutePointsAirQuality>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling getAirQualityForCoordinatesArray.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pollutantId !== undefined) {
            queryParameters['pollutantId'] = requestParameters.pollutantId;
        }

        if (requestParameters.routingTime !== undefined) {
            queryParameters['routingTime'] = (requestParameters.routingTime as any).toISOString();
        }

        if (requestParameters.routingEnd !== undefined) {
            queryParameters['routingEnd'] = (requestParameters.routingEnd as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/airQuality`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RoutePointsAirQualityFromJSON(jsonValue));
    }

    /**
     * Gets air quality for array of coordinates (passed as request body) from airquality data file and optionally user\'s calculated exposure.
     */
    async getAirQualityForCoordinatesArray(requestParameters: GetAirQualityForCoordinatesArrayRequest): Promise<RoutePointsAirQuality> {
        const response = await this.getAirQualityForCoordinatesArrayRaw(requestParameters);
        return await response.value();
    }

}
