import React from "react";
import { AppBar, Box, Button, Toolbar, Typography, withStyles, WithStyles } from "@material-ui/core";
import strings from "../../../localization/strings";
import { NullableToken, HeaderNavigationLinks } from "../../../types";
import { styles } from "./header.styles";
import { Link } from "react-router-dom";
import LogoIcon from "../../../resources/svg/logo-icon";
import StatsIcon from "@material-ui/icons/ShowChart";
import MapIcon from "@material-ui/icons/Map";
import SettingsIcon from "@material-ui/icons/Settings";
import jwt_decode from "jwt-decode";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  currentHeaderNavigationLink?: HeaderNavigationLinks
  accessToken?: NullableToken;
  keycloak?: Keycloak.KeycloakInstance;
  onSettingsClick?: () => void;
}

/**
 * Interface describing component state
 */
interface State {
}

/**
 * Component for header
 */
class Header extends React.Component<Props, State> {

  /**
   * Component constructor
   * 
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = { };
  }

  public render = () => {
    const { classes } = this.props;

    return (
      <>
        <AppBar className={ classes.appBar }>
          <Toolbar>
            <Box display="flex" flexGrow={ 1 }>
              <Link to="/">
                <Box className={ classes.logoBox }>
                  <LogoIcon className={ classes.logoIcon }/>
                  <Typography className={ classes.logoText } >
                    { strings.header.logoText }
                  </Typography>
                </Box>
              </Link>
              { this.renderMap() }
              { this.renderStatistics() }
              { this.renderManagement() }
              { this.renderSettings() }
            </Box>
            { Header.renderAbout() }
            { this.renderAuthAction() }
          </Toolbar>
        </AppBar>
      </>
    );
  };

  /**
   * Method for rendering user auth action
   */
  private renderAuthAction = () => {
    const { accessToken } = this.props;

    const label = accessToken ?
      strings.auth.logout :
      strings.auth.login;

    return (
      <Box style={{ marginLeft: "auto" }}>
        <Button
          color="primary"
          variant="text"
          onClick={ () => {
            this.onLoginClick();
          }}
        >
          { label }
        </Button>
      </Box>
    );
  };

  /**
   * Method for rendering map
   */
  private renderMap = () => {
    const { currentHeaderNavigationLink } = this.props;

    if (currentHeaderNavigationLink === HeaderNavigationLinks.map) {
      return (
        <Box ml={ 2 } mr={ 2 }>
          <Link to="/map">
            <Button
              startIcon={ <MapIcon/> }
              color="primary"
              variant="contained"
            >
              { strings.header.map }
            </Button>
          </Link>
        </Box>
      );
    }

    return (
      <Box ml={ 2 } mr={ 2 }>
        <Link to="/map">
          <Button
            startIcon={ <MapIcon/> }
            variant="text"
          >
            { strings.header.map }
          </Button>
        </Link>
      </Box>
    );
  };

  /**
   * Method for rendering statistics
   */
  private renderStatistics = () => {
    const { accessToken, currentHeaderNavigationLink } = this.props;

    if (!accessToken) {
      return null;
    }

    if (currentHeaderNavigationLink === HeaderNavigationLinks.statistics) {
      return (
        <Box mr={ 2 }>
          <Link to="/statistics">
            <Button
              startIcon={ <StatsIcon/> }
              color="primary"
              variant="contained"
            >
              { strings.header.statistics }
            </Button>
          </Link>
        </Box>
      );
    }

    return (
      <Box mr={ 2 }>
        <Link to="/statistics">
          <Button
            startIcon={ <StatsIcon/> }
            variant="text"
          >
            { strings.header.statistics }
          </Button>
        </Link>
      </Box>
    );
  };
  
  /**
   * Method for rendering management
   */
  private renderManagement = () => {
    const { accessToken, currentHeaderNavigationLink } = this.props;

    if (!accessToken) {
      return null;
    }

    const decodedToken: any = jwt_decode(accessToken.access_token);
    const roles = decodedToken.realm_access.roles as string[];

    if (!roles.includes("admin")) {
      return null;
    }

    if (currentHeaderNavigationLink === HeaderNavigationLinks.management) {
      return (
        <Box mr={ 2 }>
          <Link to="/management">
            <Button
              startIcon={ <SupervisorAccountIcon/> }
              color="primary"
              variant="contained"
            >
              { strings.header.management }
            </Button>
          </Link>
        </Box>
      );
    }

    return (
      <Box mr={ 2 }>
        <Link to="/management">
          <Button
            startIcon={ <SupervisorAccountIcon/> }
            variant="text"
          >
            { strings.header.management }
          </Button>
        </Link>
      </Box>
    );
  };

  /**
   * Method for rendering settings
   */
  private renderSettings = () => {
    const { accessToken, onSettingsClick } = this.props;
    
    if (!accessToken) {
      return null;
    }

    return (
      <Box>
        <Button
          onClick={ onSettingsClick }
          variant="text"
          startIcon={ <SettingsIcon/> }
        >
          { strings.header.settings }
        </Button>
      </Box>
    );
  };

  /**
   * Renders about link.
   */
  static renderAbout = () => {
    return (
      <Box>
        <Link to="/about">
          <Button
            color="primary"
            variant="text"
          >
            { strings.header.about }
          </Button>
        </Link>
      </Box>
    );
  };

  /**
   * Handles Login / Logout button click
   */
  private onLoginClick = () => {
    const { accessToken, keycloak } = this.props;
    if (accessToken) {
      if (keycloak) {
        keycloak.logout();
      }
    } else if (keycloak) {
      keycloak.login({ idpHint: "oidc" });
    }
  };

}

export default withStyles(styles)(Header);