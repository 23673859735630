import { LatLng } from "leaflet";
import { DefaultRoutingMode, Pollutant, MedicalCondition, AirQuality } from "../generated/client/models";

export type Locale = "fi" | "en";

/**
 * Enum for determining coordinate direction
 */
export enum GeocodeCoordinate {
  To,
  From
}

/**
 * Union type for nullable access token
 */
export type NullableToken = AccessToken | null;

/**
 * Enum for all route of the web app
 */
export enum HeaderNavigationLinks {
  management,
  statistics,
  map
}

/**
 * Enum for all management views
 */
export enum ManagementViews {
  medicalCondition,
  defaultRouting,
  pollutantLimit
}

/**
 * Enum for statistics screen selected scope
 */
export enum StatisticScope {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  YEAR = "year"
}

/**
 * Interface describing routing mode
 */
export interface RoutingModeData{
  name: string;
  associatedRouteData?: RouteData;
}

/**
 * Type for popup options
 */
export type PopupOptions = "source" | "destination" | "options";

/**
 * Type for managed item
 */
export type ManagedItem = MedicalCondition | DefaultRoutingMode;

/**
 * Interface describing an access token
 */
export interface AccessToken {
  created: Date;
  access_token: string;
  expires_in?: number;
  refresh_token?: string;
  refresh_expires_in?: number;
  userName?: string;
  firstName?: string;
  lastName?: string;
  userId?: string;
  email?: string;
}

/**
 * Interface describing a location
 */
export interface Location {
  name: string;
  coordinates: LatLng;
}

/**
 * Interface describing routing modes
 */
export interface RoutingModeIcons {
  Strict: JSX.Element;
  Efficient: JSX.Element;
  Relaxed: JSX.Element;
  Custom?: JSX.Element;
}

/**
 * Interface describing coordinate point
 */
export interface PointCoordinates {
  lat: number;
  lng: number;
}

/**
 * Interface describing route data
 */
export interface RouteData {
  lineCoordinates: LatLng[];
  duration?: string;
  totalExposures?: RouteTotalAirQuality[];
  routeAirQuality?: AirQuality[]
}

/**
 * Total exposure of pollutant for the route
 */
export interface RouteTotalAirQuality {
  pollutant: Pollutant;
  pollutionValue: number;
}

/**
 * Interface describing custom pollutant type
 */
export interface CustomPollutant {
  modified: boolean;
  recommendationPercentage: string;
  pollutant: Pollutant;
}

/**
 * Interface describing parser util parameter
 */
export interface OTPParserParams {
  defaultRoutingMode: DefaultRoutingMode,
  pollutants: Pollutant[],
  time: string,
  date: string,
  maxWalkDistance: number,
  baseUrl?: string,
  fromPlace?: LatLng,
  toPlace?: LatLng
}

export interface MapBound {
  coordinate1: LatLng;
  coordinate2: LatLng;
}

/**
 * Type exposure statistics data
 */
export interface ExposureStatisticsData {
  name: string;
  exposureAmount: number;
}

/**
 * Type exposure pollutant statistics data
 */
export interface ExposurePollutantData {
  pollutant: Pollutant;
  exposureData: ExposureStatisticsData[];
}