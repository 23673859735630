/* tslint:disable */
/* eslint-disable */
/**
 * Megasense data portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DefaultRoutingMode,
    DefaultRoutingModeFromJSON,
    DefaultRoutingModeToJSON,
} from '../models';

export interface CreateRoutingModeRequest {
    defaultRoutingMode: DefaultRoutingMode;
}

export interface DeleteRoutingModeRequest {
    routingModeId: string;
}

export interface FindRoutingModeRequest {
    routingModeId: string;
}

export interface GetRoutingModesRequest {
    routingModeName?: string;
}

export interface UpdateRoutingModeRequest {
    defaultRoutingMode: DefaultRoutingMode;
    routingModeId: string;
}

/**
 * 
 */
export class DefaultRoutingModesApi extends runtime.BaseAPI {

    /**
     * Creates a new default routing mode
     */
    async createRoutingModeRaw(requestParameters: CreateRoutingModeRequest): Promise<runtime.ApiResponse<DefaultRoutingMode>> {
        if (requestParameters.defaultRoutingMode === null || requestParameters.defaultRoutingMode === undefined) {
            throw new runtime.RequiredError('defaultRoutingMode','Required parameter requestParameters.defaultRoutingMode was null or undefined when calling createRoutingMode.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/routingModes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DefaultRoutingModeToJSON(requestParameters.defaultRoutingMode),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultRoutingModeFromJSON(jsonValue));
    }

    /**
     * Creates a new default routing mode
     */
    async createRoutingMode(requestParameters: CreateRoutingModeRequest): Promise<DefaultRoutingMode> {
        const response = await this.createRoutingModeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes routing mode
     */
    async deleteRoutingModeRaw(requestParameters: DeleteRoutingModeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.routingModeId === null || requestParameters.routingModeId === undefined) {
            throw new runtime.RequiredError('routingModeId','Required parameter requestParameters.routingModeId was null or undefined when calling deleteRoutingMode.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/routingModes/{routingModeId}`.replace(`{${"routingModeId"}}`, encodeURIComponent(String(requestParameters.routingModeId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes routing mode
     */
    async deleteRoutingMode(requestParameters: DeleteRoutingModeRequest): Promise<void> {
        await this.deleteRoutingModeRaw(requestParameters);
    }

    /**
     * Finds a routing mode
     */
    async findRoutingModeRaw(requestParameters: FindRoutingModeRequest): Promise<runtime.ApiResponse<DefaultRoutingMode>> {
        if (requestParameters.routingModeId === null || requestParameters.routingModeId === undefined) {
            throw new runtime.RequiredError('routingModeId','Required parameter requestParameters.routingModeId was null or undefined when calling findRoutingMode.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/routingModes/{routingModeId}`.replace(`{${"routingModeId"}}`, encodeURIComponent(String(requestParameters.routingModeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultRoutingModeFromJSON(jsonValue));
    }

    /**
     * Finds a routing mode
     */
    async findRoutingMode(requestParameters: FindRoutingModeRequest): Promise<DefaultRoutingMode> {
        const response = await this.findRoutingModeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists all default routing modes
     */
    async getRoutingModesRaw(requestParameters: GetRoutingModesRequest): Promise<runtime.ApiResponse<Array<DefaultRoutingMode>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.routingModeName !== undefined) {
            queryParameters['routingModeName'] = requestParameters.routingModeName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/routingModes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DefaultRoutingModeFromJSON));
    }

    /**
     * Lists all default routing modes
     */
    async getRoutingModes(requestParameters: GetRoutingModesRequest): Promise<Array<DefaultRoutingMode>> {
        const response = await this.getRoutingModesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates routing mode
     */
    async updateRoutingModeRaw(requestParameters: UpdateRoutingModeRequest): Promise<runtime.ApiResponse<DefaultRoutingMode>> {
        if (requestParameters.defaultRoutingMode === null || requestParameters.defaultRoutingMode === undefined) {
            throw new runtime.RequiredError('defaultRoutingMode','Required parameter requestParameters.defaultRoutingMode was null or undefined when calling updateRoutingMode.');
        }

        if (requestParameters.routingModeId === null || requestParameters.routingModeId === undefined) {
            throw new runtime.RequiredError('routingModeId','Required parameter requestParameters.routingModeId was null or undefined when calling updateRoutingMode.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/routingModes/{routingModeId}`.replace(`{${"routingModeId"}}`, encodeURIComponent(String(requestParameters.routingModeId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DefaultRoutingModeToJSON(requestParameters.defaultRoutingMode),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultRoutingModeFromJSON(jsonValue));
    }

    /**
     * Updates routing mode
     */
    async updateRoutingMode(requestParameters: UpdateRoutingModeRequest): Promise<DefaultRoutingMode> {
        const response = await this.updateRoutingModeRaw(requestParameters);
        return await response.value();
    }

}
