import React from "react";
import { useLeaflet } from "react-leaflet";

interface Props {
  data: number[][];
  weight: number;
  palette: { [z: number]: string };
}

/**
 * Renders a path on the map for the passed geojson file
 * that changes color according to the elevation.
 *
 * https://github.com/iosphere/Leaflet.hotline/
 */
export const HotLine: React.FC<Props> = ({ data, weight, palette }) => {
  const { map } = useLeaflet();

  React.useEffect(() => {
    if (!map) {
      return;
    }
    const L = require("leaflet-hotline");
    const hotline = L.hotline(data, {
      palette: palette,
      weight: weight,
      outlineWidth: 0
    });

    map.addLayer(hotline);

    return () => {
      map.removeLayer(hotline);
    };
  }, [weight]);

  return null;
};