import { Box, Button, Divider, Paper, Typography } from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { History } from "history";
import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import strings from "../../../localization/strings";
import { ReduxActions, ReduxState } from "../../../store";
import { NullableToken, HeaderNavigationLinks, ManagementViews } from "../../../types";
import AppLayout from "../../layouts/app-layout/app-layout";
import { styles } from "./management-screen.styles";
import jwt_decode from "jwt-decode";
import PollutantLimitView from "../../views/management-pollutant-limit-view/management-pollutant-limit-view";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import theme from "../../../theme/theme";
import RoutingModView from "../../views/management-routing-mode/management-routing-mode";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  accessToken?: NullableToken;
  keycloak?: Keycloak.KeycloakInstance;
  history: History<History.LocationState>;
}

/**
 * Interface describing component state
 */
interface State {
  activeManagementView: ManagementViews;
  error?: any;
}

/**
 * Component for management screen
 */
class Management extends React.Component<Props, State> {

  /**
   * Component constructor
   * 
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      activeManagementView: ManagementViews.medicalCondition
    };
  }
  
  /**
   * Component life cycle method
   */
  public componentDidMount = async () => {
    const { accessToken, history } = this.props;

    if (!accessToken) {
      history.push("/");
      return;
    }

    const decodedToken: any = jwt_decode(accessToken.access_token);
    const roles = decodedToken.realm_access.roles as string[];

    if (!roles.includes("admin")) {
      history.push("/");
    }
  };

  /**
   * Component render method
   */
  public render = () => {
    const { accessToken, keycloak, classes } = this.props;
    const { error } = this.state;

    return (
      <AppLayout
        currentHeaderNavigationLink={ HeaderNavigationLinks.management }
        accessToken={ accessToken }
        keycloak={ keycloak }
        error={ error }
        clearError={ this.onClearError }
      >
        <Box className={ classes.backgroundContainer }>
          <Box className={ classes.contentContainer }>
            { this.renderManagementContent() }
            { this.renderNavigationCard() }
          </Box>
        </Box>
      </AppLayout>
    );
  };

  /**
   * Renders navigation content
   */
  public renderManagementContent = () => {
    const { activeManagementView } = this.state;

    switch (activeManagementView) {
      case ManagementViews.defaultRouting:
        return (
          <RoutingModView
            setError={ this.onSetError }
          />
        );
      default: // case ManagementViews.pollutantLimit:
        return (
          <PollutantLimitView
            setError={ this.onSetError }
          />
        );
    }
  };

  /**
   * Renders navigation card
   */
  public renderNavigationCard = () => {
    const { classes } = this.props;

    return (
      <Paper className={ classes.navigationCard }>
        <Box className={ classes.navigationTitle }>
          <Typography className={ classes.navigationTitleText }>
            { strings.management.navigation }
          </Typography>
        </Box>
        <Box margin={ `${theme.spacing(1)}px 0px` }>
          <Divider/>
        </Box>
        <Box>
          {
            this.renderNavigationButton(
              strings.management.defaultRoutingModes.title,
              ManagementViews.defaultRouting,
              this.onDefaultRoutingNavClick
            )
          }
          {
            this.renderNavigationButton(
              strings.management.pollutantLimit.title,
              ManagementViews.pollutantLimit,
              this.onPollutantLimitNavClick
            )
          }
        </Box>
      </Paper>
    );
  };

  /**
   * Renders navigation Button
   * 
   * @param buttonText button text
   * @param managementView current management view
   * @param onButtonClick on button click callback
   */
  public renderNavigationButton = (buttonText: string, managementView: ManagementViews, onButtonClick: React.MouseEventHandler<HTMLButtonElement>) => {
    const { classes } = this.props;
    const { activeManagementView } = this.state;
    
    if (activeManagementView === managementView) {
      return (
        <Button
          disabled
          variant="text"
          onClick={ onButtonClick }
          className={ classes.navigationButton }
        >
          <Box className={ classes.navigationButtonContent }>
            <ArrowRightIcon/>
            <Typography style={{ marginLeft: theme.spacing(1) }}>
              { buttonText }
            </Typography>
          </Box>
        </Button>
      );
    }

    return (
      <Button
        variant="text"
        onClick={ onButtonClick }
        className={ classes.navigationButton }
      >
        <Box className={ classes.navigationButtonContent }>
          <ArrowRightIcon htmlColor="rgba(0, 0, 0, 0)"/>
          <Typography style={{ marginLeft: theme.spacing(1) }}>
            { buttonText }
          </Typography>
        </Box>
      </Button>
    );
  };

  /**
   * Handles Error message setting
   */
  private onSetError = (error: string | Error | Response) => {
    this.setState({
      error: error
    });
  };

  /**
   * Handles Error message clearing
   */
  private onClearError = () => {
    this.setState({
      error: undefined
    });
  };

  /**
   * Medical condition navigation link click handler 
   */
  private onMedicalConditionNavClick = () => {
    this.setState({
      activeManagementView: ManagementViews.medicalCondition
    });
  };

  /**
   * Default routing mode navigation link click handler 
   */
  private onDefaultRoutingNavClick = () => {
    this.setState({
      activeManagementView: ManagementViews.defaultRouting
    });
  };

  /**
   * Pollutant limit navigation link click handler 
   */
  private onPollutantLimitNavClick = () => {
    this.setState({
      activeManagementView: ManagementViews.pollutantLimit
    });
  };

}

/**
 * Redux mapper for mapping store state to component props
 * @param state store state
 */
export function mapStateToProps(state: ReduxState) {
  return {
    accessToken: state.auth.accessToken,
    keycloak: state.auth.keycloak
  };
}

/**
 * Redux mapper for mapping component dispatches 
 * 
 * @param dispatch dispatch method
 */
export function mapDispatchToProps(dispatch: Dispatch<ReduxActions>) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Management));