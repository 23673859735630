import { LatLngBounds, latLng } from "leaflet";
import { Viewport } from "react-leaflet";
import { GetAirQualityRequest, Pollutant } from "../generated/client";
import moment from "moment";
/**
 * Utility class for for modifications on airquality and pollutant data
 */
export default class PollutantUtils {

  /**
     * Creates the airquality query, based on map bounds
     * 
     * @param mapBounds latitude longtitude of the visible map
     * @param departureTime departure time as Date 
     * @returns query of the format GetAirQualityRequest
     */
  public static getAirQualityQuery = (mapBounds: LatLngBounds, departureTime: Date): GetAirQualityRequest => {
    return {
      boundingBoxCorner1: `${PollutantUtils.getSouth(mapBounds)},${PollutantUtils.getWest(mapBounds)}`,
      boundingBoxCorner2: `${PollutantUtils.getNorth(mapBounds)},${PollutantUtils.getEast(mapBounds)}`,
      routingTime: departureTime
    };
  };

  /**
     * Calculates bounds based on a center point
     * 
     * @param mapViewPort map viewport instance
     * @returns LatLngBounds for the center point
     */
  public static createDefaultBounds = (mapViewPort: Viewport) => {
    const south = Math.floor(mapViewPort.center?.[0] as number) - 2;
    const north = Math.ceil(mapViewPort.center?.[0] as number) + 2;
    const east = Math.ceil(mapViewPort.center?.[1] as number) + 2;
    const west = Math.floor(mapViewPort.center?.[1] as number) - 2;
    return new LatLngBounds(latLng(south, west), latLng(north, east));
  };

  /**
     * Calculates an extention to south of a LatLngBounds
     * 
     * @param mapBounds latitude longtitude of the visible map
     * @returns floor of the value for south as string format
     */
  private static getSouth = (mapBounds: LatLngBounds) => {
    return Math.floor(-2 + mapBounds.getSouth() as number).toString();
  };

  /**
     * Calculates an extention to north of a LatLngBounds
     * 
     * @param mapBounds latitude longtitude of the visible map
     * @returns ceil of the value for north as string format
     */
  private static getNorth = (mapBounds: LatLngBounds) => {
    return Math.ceil(2 + mapBounds.getNorth() as number).toString();
  };

  /**
     * Calculates an extention to east of a LatLngBounds
     * 
     * @param mapBounds latitude longtitude of the visible map
     * @returns ceil of the value for east as string format
     */
  private static getEast = (mapBounds: LatLngBounds) => {
    return Math.ceil(2 + mapBounds.getEast() as number).toString();
  };

  /**
     * Calculates an extention to west of a LatLngBounds
     * 
     * @param mapBounds latitude longtitude of the visible map
     * @returns floor of the value for west as string format
     */
  private static getWest = (mapBounds: LatLngBounds) => {
    return Math.floor(-2 + mapBounds.getWest() as number).toString();
  };

  /**
     * Calculates the layer string for WMS layer
     * 
     * @param selectedPollutant Pollutant that is selected
     * @returns string format for layer option of WMS layer
     */
  public static getWMSLayers = (selectedPollutant: Pollutant) => {
    const nameSpace = process.env.REACT_APP_LOCATION;
        
    return `${nameSpace}:${selectedPollutant.variableName}`;
  };

  /**
     * Get the date and hours only time in iso format 
     * 
     * @param departureDate Date Departure date
     * @param departureTime Date Departure time
     * @returns string iso format
     */
  public static getTimeInISO(departureDate: Date, departureTime: Date) {
    const formattedTime = moment(departureTime).utc().format("HH:00:00.000");
    const formattedDate = moment(departureDate).format("yyyy-MM-DD");
        
    return `${formattedDate}T${formattedTime}Z`;
  }

}