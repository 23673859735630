import { createStyles } from "@material-ui/core";
import backgroundImage from "../../../resources/images/main.jpg";
import theme from "../../../theme/theme";
import { fade } from "@material-ui/core/styles/colorManipulator";

export const styles = createStyles({

  backgroundContainer: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    height: "calc(100vh - 64px)",
    justifyContent: "center",
    display: "flex",
    position: "relative"
  },

  contentContainer: {
    marginTop: theme.spacing(6),
    display: "flex"
  },

  navigationCard: {
    width: 350,
    position: "absolute",
    top: theme.spacing(8),
    left: theme.spacing(8),
    padding: theme.spacing(2),
    [theme.breakpoints.down("lg")]: {
      maxWidth: 350,
      width: `calc(((100vw - 720px) / 2) - ${theme.spacing(16)}px)`,
      minWidth: 270
    },
    [theme.breakpoints.down("md")]: {
      width: 270
    }
  },

  navigationTitle: {
    padding: theme.spacing(1)
  },

  navigationTitleText: {
    fontSize: 28,
    fontWeight: 600,
    color: "rgba(0, 0, 0, 0.7)"
  },

  navigationButton: {
    width: "100%",
    height: 50,
    borderRadius: 0,
    "&.Mui-disabled": {
      color: theme.palette.primary.main
    },
    "&:hover": {
      backgroundColor: fade(theme.palette.primary.main, 0.3)
    }
  },

  navigationButtonContent: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyItems: "start"
  }

});