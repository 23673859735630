/* tslint:disable */
/* eslint-disable */
/**
 * Megasense data portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RouteHistoryType,
    RouteHistoryTypeFromJSON,
    RouteHistoryTypeFromJSONTyped,
    RouteHistoryTypeToJSON,
    RouteStep,
    RouteStepFromJSON,
    RouteStepFromJSONTyped,
    RouteStepToJSON,
    TransportationType,
    TransportationTypeFromJSON,
    TransportationTypeFromJSONTyped,
    TransportationTypeToJSON,
} from './';

/**
 * Saved route, either for route history or as favorite.
 * @export
 * @interface Route
 */
export interface Route {
    /**
     * 
     * @type {string}
     * @memberof Route
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Route
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Route
     */
    routePoints: string;
    /**
     * Compressed location names along the route
     * @type {string}
     * @memberof Route
     */
    locationNames: string;
    /**
     * 
     * @type {string}
     * @memberof Route
     */
    locationFromName: string;
    /**
     * 
     * @type {string}
     * @memberof Route
     */
    locationToName: string;
    /**
     * 
     * @type {RouteHistoryType}
     * @memberof Route
     */
    routeHistoryType: RouteHistoryType;
    /**
     * 
     * @type {TransportationType}
     * @memberof Route
     */
    transportationType: TransportationType;
    /**
     * 
     * @type {boolean}
     * @memberof Route
     */
    isPublic: boolean;
    /**
     * 
     * @type {Array<RouteStep>}
     * @memberof Route
     */
    steps?: Array<RouteStep>;
    /**
     * 
     * @type {Date}
     * @memberof Route
     */
    savedAt?: Date;
    /**
     * Length of the saved route in meters
     * @type {number}
     * @memberof Route
     */
    routeDistance?: number;
    /**
     * Time of the saved route in seconds
     * @type {number}
     * @memberof Route
     */
    routeTime?: number;
}

export function RouteFromJSON(json: any): Route {
    return RouteFromJSONTyped(json, false);
}

export function RouteFromJSONTyped(json: any, ignoreDiscriminator: boolean): Route {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'routePoints': json['routePoints'],
        'locationNames': json['locationNames'],
        'locationFromName': json['locationFromName'],
        'locationToName': json['locationToName'],
        'routeHistoryType': RouteHistoryTypeFromJSON(json['routeHistoryType']),
        'transportationType': TransportationTypeFromJSON(json['transportationType']),
        'isPublic': json['isPublic'],
        'steps': !exists(json, 'steps') ? undefined : ((json['steps'] as Array<any>).map(RouteStepFromJSON)),
        'savedAt': !exists(json, 'savedAt') ? undefined : (new Date(json['savedAt'])),
        'routeDistance': !exists(json, 'routeDistance') ? undefined : json['routeDistance'],
        'routeTime': !exists(json, 'routeTime') ? undefined : json['routeTime'],
    };
}

export function RouteToJSON(value?: Route | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'routePoints': value.routePoints,
        'locationNames': value.locationNames,
        'locationFromName': value.locationFromName,
        'locationToName': value.locationToName,
        'routeHistoryType': RouteHistoryTypeToJSON(value.routeHistoryType),
        'transportationType': TransportationTypeToJSON(value.transportationType),
        'isPublic': value.isPublic,
        'steps': value.steps === undefined ? undefined : ((value.steps as Array<any>).map(RouteStepToJSON)),
        'savedAt': value.savedAt === undefined ? undefined : (value.savedAt.toISOString()),
        'routeDistance': value.routeDistance,
        'routeTime': value.routeTime,
    };
}


