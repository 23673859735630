/* tslint:disable */
/* eslint-disable */
/**
 * Megasense data portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Route transportantion type
 * @export
 * @enum {string}
 */
export enum TransportationType {
    WALKING = 'WALKING',
    BIKING = 'BIKING',
    ACCESSIBILITY = 'ACCESSIBILITY'
}

export function TransportationTypeFromJSON(json: any): TransportationType {
    return TransportationTypeFromJSONTyped(json, false);
}

export function TransportationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransportationType {
    return json as TransportationType;
}

export function TransportationTypeToJSON(value?: TransportationType | null): any {
    return value as any;
}

