/* tslint:disable */
/* eslint-disable */
/**
 * Megasense data portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    RoutePollutionExposureRecord,
    RoutePollutionExposureRecordFromJSON,
    RoutePollutionExposureRecordToJSON,
} from '../models';

export interface CreateRoutePollutionExposureRecordRequest {
    routePollutionExposureRecord: RoutePollutionExposureRecord;
}

export interface DeleteRoutePollutionExposureRecordRequest {
    routePollutionExposureRecordId: string;
}

export interface FindRoutePollutionExposureRecordRequest {
    routePollutionExposureRecordId: string;
}

export interface ListRoutePollutionExposureRecordsRequest {
    routeId?: string;
    createdBefore?: Date;
    createdAfter?: Date;
}

/**
 * 
 */
export class RoutePollutionExposureRecordsApi extends runtime.BaseAPI {

    /**
     * Creates a pollution record for the user on the route
     * Creates an exposure instance
     */
    async createRoutePollutionExposureRecordRaw(requestParameters: CreateRoutePollutionExposureRecordRequest): Promise<runtime.ApiResponse<RoutePollutionExposureRecord>> {
        if (requestParameters.routePollutionExposureRecord === null || requestParameters.routePollutionExposureRecord === undefined) {
            throw new runtime.RequiredError('routePollutionExposureRecord','Required parameter requestParameters.routePollutionExposureRecord was null or undefined when calling createRoutePollutionExposureRecord.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/routePollutionExposureRecords`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RoutePollutionExposureRecordToJSON(requestParameters.routePollutionExposureRecord),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RoutePollutionExposureRecordFromJSON(jsonValue));
    }

    /**
     * Creates a pollution record for the user on the route
     * Creates an exposure instance
     */
    async createRoutePollutionExposureRecord(requestParameters: CreateRoutePollutionExposureRecordRequest): Promise<RoutePollutionExposureRecord> {
        const response = await this.createRoutePollutionExposureRecordRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes an exposure instance
     */
    async deleteRoutePollutionExposureRecordRaw(requestParameters: DeleteRoutePollutionExposureRecordRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.routePollutionExposureRecordId === null || requestParameters.routePollutionExposureRecordId === undefined) {
            throw new runtime.RequiredError('routePollutionExposureRecordId','Required parameter requestParameters.routePollutionExposureRecordId was null or undefined when calling deleteRoutePollutionExposureRecord.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/routePollutionExposureRecords/{RoutePollutionExposureRecordId}`.replace(`{${"RoutePollutionExposureRecordId"}}`, encodeURIComponent(String(requestParameters.routePollutionExposureRecordId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes an exposure instance
     */
    async deleteRoutePollutionExposureRecord(requestParameters: DeleteRoutePollutionExposureRecordRequest): Promise<void> {
        await this.deleteRoutePollutionExposureRecordRaw(requestParameters);
    }

    /**
     * Finds an exposure record
     */
    async findRoutePollutionExposureRecordRaw(requestParameters: FindRoutePollutionExposureRecordRequest): Promise<runtime.ApiResponse<RoutePollutionExposureRecord>> {
        if (requestParameters.routePollutionExposureRecordId === null || requestParameters.routePollutionExposureRecordId === undefined) {
            throw new runtime.RequiredError('routePollutionExposureRecordId','Required parameter requestParameters.routePollutionExposureRecordId was null or undefined when calling findRoutePollutionExposureRecord.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/routePollutionExposureRecords/{RoutePollutionExposureRecordId}`.replace(`{${"RoutePollutionExposureRecordId"}}`, encodeURIComponent(String(requestParameters.routePollutionExposureRecordId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RoutePollutionExposureRecordFromJSON(jsonValue));
    }

    /**
     * Finds an exposure record
     */
    async findRoutePollutionExposureRecord(requestParameters: FindRoutePollutionExposureRecordRequest): Promise<RoutePollutionExposureRecord> {
        const response = await this.findRoutePollutionExposureRecordRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists pollution records that user was exposed to during routing (pollution data comes from user history, not from air quality data file)
     * Lists exposure instances
     */
    async listRoutePollutionExposureRecordsRaw(requestParameters: ListRoutePollutionExposureRecordsRequest): Promise<runtime.ApiResponse<Array<RoutePollutionExposureRecord>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.routeId !== undefined) {
            queryParameters['routeId'] = requestParameters.routeId;
        }

        if (requestParameters.createdBefore !== undefined) {
            queryParameters['createdBefore'] = (requestParameters.createdBefore as any).toISOString();
        }

        if (requestParameters.createdAfter !== undefined) {
            queryParameters['createdAfter'] = (requestParameters.createdAfter as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/routePollutionExposureRecords`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoutePollutionExposureRecordFromJSON));
    }

    /**
     * Lists pollution records that user was exposed to during routing (pollution data comes from user history, not from air quality data file)
     * Lists exposure instances
     */
    async listRoutePollutionExposureRecords(requestParameters: ListRoutePollutionExposureRecordsRequest): Promise<Array<RoutePollutionExposureRecord>> {
        const response = await this.listRoutePollutionExposureRecordsRaw(requestParameters);
        return await response.value();
    }

}
