import moment, { Moment } from "moment";
import { Pollutant, PollutionEntry, RoutePollutionExposureRecord } from "../generated/client";
import strings from "../localization/strings";
import { ExposurePollutantData, ExposureStatisticsData, StatisticScope } from "../types";

/**
 * Utility class for statistics screen
 */
export default class StatisticsUtils {

  // start date and end date here are all inclusive!!!

  /**
   * Return a standardized date string
   * 
   * @param date date data
   * @return date string in format of Do MMM YYYY
   */
  public static standardizedDateString = (date: Moment): string => {
    return date.format("Do MMM YYYY");
  };

  /**
   * Return a standardized date time string
   * 
   * @param date date data
   * @return date time string in format of HH a
   */
  public static standardizedDateTimeString = (date: Moment): string => {
    return moment(date).minutes(0).format("HH a");
  };

  /**
   * Return a standardized month date string
   * 
   * @param date date data
   * @return date string in format of Do MMM
   */
  public static standardizedMonthDateString = (date: Moment): string => {
    return date.format("Do MMM");
  };

  /**
   * Return a standardized week string
   * 
   * @param date date data
   * @return date string in format of week WEEK_NUMBER
   */
  public static standardizedWeekString = (date: Moment): string => {
    return `${strings.statistics.week} ${date.week()}`;
  };

  /**
   * Return a standardized month string
   * 
   * @param date date data
   * @return date string in format of MMM YYYY
   */
  public static standardizedMonthString = (date: Moment): string => {
    return date.format("MMM YYYY");
  };

  /**
   * Return a standardized year string
   * 
   * @param date date data
   * @return date time string in format of YYYY
   */
  public static standardizedYearString = (date: Moment): string => {
    return date.format("YYYY");
  };

  /**
   * Return the start date of the given scope 
   * 
   * @param statisticScope statistic scope
   * @param selectedDate selected date
   * @return start date of the given scope
   */
  public static startOfScope = (statisticScope: StatisticScope, selectedDate: Moment): Moment => {
    return moment(selectedDate).startOf(statisticScope as moment.unitOfTime.StartOf);
  };

  /**
   * Generate date (range) string for the selected scope 
   *
   * @param statisticScope statistic scope
   * @param selectedDate selected date
   * @returns date string for the selected scope
   */
  public static formatDateString = (statisticScope: StatisticScope, selectedDate: Moment): string => {
    const momentScope = statisticScope as moment.unitOfTime.StartOf;

    const startDate = moment(selectedDate).startOf(momentScope);

    switch (statisticScope) {
      case StatisticScope.DAY:
        return StatisticsUtils.standardizedDateString(startDate);
      case StatisticScope.WEEK:
        return StatisticsUtils.standardizedWeekString(startDate);
      case StatisticScope.MONTH:
        return StatisticsUtils.standardizedMonthString(startDate);
      default: // case StatisticScope.YEAR:
        return StatisticsUtils.standardizedYearString(startDate);
    }
  };

  /**
   * Get time range for the selected scope 
   *    
   * @param statisticScope statistic scope
   * @param selectedDate selected date
   * @returns current week number
   */
  public static getTimeRange = (statisticScope: StatisticScope, selectedDate: Moment) => {
    const momentScope = statisticScope as moment.unitOfTime.StartOf;
    const startDate = moment(selectedDate).startOf(momentScope);
    const endDate = moment(startDate).endOf(momentScope);

    return [ startDate, endDate ];
  };

  /**
   * Increment date by the selected scope
   * 
   * @param statisticScope statistic scope
   * @param selectedDate selected date
   * @returns incremented date
   */
  public static dateIncrement = (statisticScope: StatisticScope, selectedDate: Moment) => {
    const startDate = moment(selectedDate).startOf(statisticScope as moment.unitOfTime.StartOf);
    return moment(startDate).add(1, statisticScope as moment.unitOfTime.DurationConstructor);
  };

  /**
   * Decrement date by the selected scope
   * 
   * @param statisticScope statistic scope
   * @param selectedDate selected date
   * @returns decremented date
   */
  public static dateDecrement = (statisticScope: StatisticScope, selectedDate: Moment) => {
    const startDate = moment(selectedDate).startOf(statisticScope as moment.unitOfTime.StartOf);
    return moment(startDate).subtract(1, statisticScope as moment.unitOfTime.DurationConstructor);
  };

  /**
   * Check if the duration is in range
   * 
   * @param rangeStart range start
   * @param rangeEnd range end
   * @param durationStart duration end
   * @param durationEnd duration end
   * @returns boolean indicate if in range
   */
  public static durationInRange = (rangeStart: Moment, rangeEnd: Moment, durationStart: Moment, durationEnd: Moment): boolean => {
    return !durationEnd.isBefore(rangeStart) && !durationStart.isAfter(rangeEnd);
  };

  /**
   * Calculate the average pollution value over pollution entries
   * 
   * @param pollutionEntries pollution entries
   * @returns average value
   */
  public static pollutionEntriesAverage = (pollutionEntries: PollutionEntry[]): number => {
    if (pollutionEntries.length === 0) {
      return 0;
    }
    return pollutionEntries.reduce((prevValue, curEntry) => (prevValue + curEntry.value!), 0) / pollutionEntries.length;
  };

  /**
   * Reduce exposure statistics array to a mean value
   * 
   * @param exposureStatisticsDataArray exposure statistics data array
   * @returns Reduced data
   */
  public static exposureStatisticsDataReduce = (exposureStatisticsDataArray: ExposureStatisticsData[]): number => {
    return exposureStatisticsDataArray.reduce((prevValue, curValue) => prevValue + curValue.exposureAmount, 0) / exposureStatisticsDataArray.length;
  };

  /**
   * Preprocess exposure statistics data
   * 
   * @param exposureRecords exposure instance array
   * @param statisticScope statistic scope
   * @param selectedDate selected date
   * @param pollutants pollutant array
   * @returns Exposure data corresponding to pollutants
   */
  public static exposureStatisticsPreprocess = (exposureRecords: RoutePollutionExposureRecord[], statisticScope: StatisticScope, selectedDate: Moment, pollutants: Pollutant[]): ExposurePollutantData[] => {
    const exposurePollutantDataArray: ExposurePollutantData[] = [];

    pollutants.forEach(pollutant => {
      if (!pollutant.id) {
        return;
      }

      const exposurePollutantData: ExposurePollutantData = {
        pollutant: pollutant,
        exposureData: StatisticsUtils.exposurePollutantStatisticsPreprocess(exposureRecords, statisticScope, selectedDate, pollutant.id)
      };
      
      exposurePollutantDataArray.push(exposurePollutantData);
    });

    return exposurePollutantDataArray;
  };

  /**
   * Preprocess the statistics data by pollutant
   * 
   * @param exposureRecords exposure instance array
   * @param statisticScope statistic scope
   * @param selectedDate selected date
   * @param pollutantId pollutant id
   * @returns decremented date
   */
  public static exposurePollutantStatisticsPreprocess = (exposureRecords: RoutePollutionExposureRecord[], statisticScope: StatisticScope, selectedDate: Moment, pollutantId: string): ExposureStatisticsData[] => {
    const incrementDuration = {
      day: "hour",
      week: "day",
      month: "day",
      year: "month"
    }[statisticScope] as moment.unitOfTime.DurationConstructor;

    const standardizedDateScopeString = {
      day: StatisticsUtils.standardizedDateTimeString,
      week: StatisticsUtils.standardizedDateString,
      month: StatisticsUtils.standardizedMonthDateString,
      year: StatisticsUtils.standardizedMonthString
    }[statisticScope];

    const exposureStatisticsArray: ExposureStatisticsData[] = [];

    const [ startDateTime, endDateTime ] = StatisticsUtils.getTimeRange(statisticScope, selectedDate);
    for (startDateTime; startDateTime.isBefore(endDateTime); startDateTime.add(1, incrementDuration)) {
      const exposuresInRange = exposureRecords.filter(exposureInstance =>
        StatisticsUtils.durationInRange(
          startDateTime,
          moment(startDateTime).endOf(incrementDuration),
          moment(exposureInstance.startedAt),
          moment(exposureInstance.endedAt)
        ));

      const exposuresPollutantData: PollutionEntry[] = [];
      exposuresInRange.forEach(exposureInRange => {
        const foundPollutionEntry = exposureInRange.pollutionDataTotals?.find(pollutionValue => pollutionValue.pollutantId === pollutantId);
        foundPollutionEntry && foundPollutionEntry.value && exposuresPollutantData.push(foundPollutionEntry);
      });

      const statisticsData: ExposureStatisticsData = {
        name: standardizedDateScopeString(startDateTime),
        exposureAmount: StatisticsUtils.pollutionEntriesAverage(exposuresPollutantData)
      };

      exposureStatisticsArray.push(statisticsData);
    }

    return exposureStatisticsArray;
  };

}