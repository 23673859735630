import { createStyles } from "@material-ui/core";
import { globalStyles } from "../../../styles/globalStyles";
import theme from "../../../theme/theme";

export const styles = createStyles({
  ...globalStyles,

  saveButton: {
    ...globalStyles.primaryButton,
    marginLeft: theme.spacing(1.5),
    borderRadius: theme.spacing(1)
  },

  loadingContainer: {
    paddingTop: theme.spacing(2),
    height: "100%",
    display: "flex",
    justifyContent: "center"
  },

  deletePenaltyButton: {
    backgroundColor: theme.palette.warning.main,
    color: "#fff",
    maxWidth: "80%",
    borderRadius: 5
  },

  addPenaltyButton: {
    marginLeft: theme.spacing(6),
    "&:hover": {
      backgroundColor: "transparent"
    }
  },

  deleteRoutingModeButton: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },

  editName: {
    display: "flex",
    alignItems: "center",
    width: 375,
    [theme.breakpoints.down("md")]: {
      width: 250
    }
  },

  editNameTextField: {
    width: 250,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },

  routingModeNameInput: {
    "& .MuiInput-underline:after": {
      borderBottom: "1px solid #000"
    },
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid #000"
    }
  },

  generalsContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },

  buttonContainer: {
    display: "flex",
    width: 320,
    marginLeft: "auto",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("lg")]: {
      width: 250
    }
  },

  deleteDraftButton: {
    ...globalStyles.warningButton,
    borderRadius: theme.spacing(1)
  },

  scrollableRoutingModesContainer: {
    flex: 1,
    overflow: "auto",
    padding: `0px ${theme.spacing(2)}px`,
    overflowX: "hidden",
    scrollbarColor: "rgba(0,0,0,0.3) transparent",
    scrollbarWidth: "auto",
    paddingBottom: theme.spacing(12),
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,0.5)",
      border: "none"
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
      color: "transparent"
    }
  },

  newPenaltyContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    padding: `0px ${theme.spacing(1)}px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },

  newRoutingMode: {
    border: "none",
    borderColor: theme.palette.primary.light,
    color: theme.palette.primary.light,
    backgroundColor: "transparent",
    display: "flex",
    alignItems: "center",
    marginLeft: "auto"
  },

  newRoutingModeText: {
    color: theme.palette.primary.light,
    fontWeight: 600
  },

  deletePenaltyButtonContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    width: 120,
    height: 56,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: `0px ${theme.spacing(1)}px`
  }

});