import { WithStyles, withStyles } from "@material-ui/core";
import React from "react";
import { RoutingModeIcon } from "../../../generated/client/models";
import { styles } from "./routing-mode-icon-renderer.styles";
import { Eco, Timer, Timeline, Favorite, ThumbUp, ShowChart } from "@material-ui/icons";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  iconName: RoutingModeIcon;
  htmlColor?: string;
}

/**
 * Interface describing component state
 */
interface State {
}

/**
 * Component for header
 */
class RoutingModeIconRenderer extends React.Component<Props, State> {

  /**
   * Component constructor
   * 
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
    };
  }

  public render = () => {
    const { iconName, htmlColor } = this.props;

    switch (iconName) {
      case RoutingModeIcon.Eco:
        return (
          <Eco htmlColor={ htmlColor }/>
        );
      case RoutingModeIcon.ThumbUp:
        return (
          <ThumbUp htmlColor={ htmlColor }/>
        );
      case RoutingModeIcon.Timer:
        return (
          <Timer htmlColor={ htmlColor }/>
        );
      case RoutingModeIcon.TimeLine:
        return (
          <Timeline htmlColor={ htmlColor }/>
        );
      case RoutingModeIcon.Insight:
        return (
          <ShowChart htmlColor={ htmlColor }/>
        );
      default:
        return (
          <Favorite htmlColor={ htmlColor }/>
        );
    }
  };

}

export default withStyles(styles)(RoutingModeIconRenderer);